import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import toparrow from "../../image/newImg/toparrow.png";
import SideBar from "../SideBar/SideBar";
import "./Content.css";
import "./Results.css";
import { Col, Row } from "react-bootstrap";
import s1 from "../../image/r10/s1.png";
import s2 from "../../image/r10/s2.png";
import s3 from "../../image/r10/s3.png";
import s4 from "../../image/r10/s4.png";
import s5 from "../../image/r10/s5.png";
import s6 from "../../image/r10/s6.png";
import s7 from "../../image/r10/s7.png";
import s8 from "../../image/r10/s8.png";
import s9 from "../../image/r10/s9.png";
import s10 from "../../image/r10/s10.png";
import s11 from "../../image/r10/s11.png";
import s12 from "../../image/r10/s12.png";

import s22 from "../../image/r12/s1.png";
import s23 from "../../image/r12/s2.png";
import s24 from "../../image/r12/s3.png";
import s25 from "../../image/r12/s4.png";
import s26 from "../../image/r12/s5.png";
import s27 from "../../image/r12/s6.png";
import s28 from "../../image/r12/s7.png";
import s29 from "../../image/r12/s8.png";
import s30 from "../../image/r12/s9.png";
import s31 from "../../image/r12/s10.png";
import s32 from "../../image/r12/s11.png";
const x12th = require("../../excel/jammu12.xlsx");
const x10th = require("../../excel/jammu10.xlsx");
const xth = require("../../excel/part-2.pdf");
function Results(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              className="admissions__image"
              src={toparrow}
              style={{ marginRight: "1rem" }}
            />{" "}
            Results
          </div>
          <Col>
            <Row className="resultsrow">
              <div className="row">
                <div className="col-lg-5">
                  <div className="result-btn mt-2">
                    <a href={x10th}>
                      <button className="tenth_result">
                        VIEW 10 YEARS’ ANALYSIS RESULT (X)
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="result-btn mt-2">
                    <a href={x12th}>
                      <button className="tenth_result">
                        VIEW 10 YEARS’ ANALYSIS RESULT (XII)
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="result-btn mt-2">
                    <a href={xth}>
                      <button className="tenth_result">
                        Board Result Class 10 and Class 12
                      </button>
                    </a>
                  </div>
                </div>
              </div>

              <div style={{ fontSize: "1.2rem", marginTop: "1.5rem" }}>
                <b>Toppers: X</b>
              </div>
              <Row>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s1} />
                    <div className="details">
                      <p className="sname">Mahima Sarup</p>
                      <p className="marks">94.4%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s2} />
                    <div className="details">
                      <p className="sname">Gopi Kishan Sahu</p>
                      <p className="marks">94.4%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s3} />
                    <div className="details">
                      <p className="sname">Vikrant Singh</p>
                      <p className="marks">94.2%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s4} />
                    <div className="details">
                      <p className="sname">Anzleen Kour</p>
                      <p className="marks">93.2%</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s5} />
                    <div className="details">
                      <p className="sname">Bhavaya Gupta</p>
                      <p className="marks">92.8%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s6} />
                    <div className="details">
                      <p className="sname">Krishna Koul</p>
                      <p className="marks">92%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s7} />
                    <div className="details">
                      <p className="sname">Mayur Kitchlo</p>
                      <p className="marks">91.9%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s8} />
                    <div className="details">
                      <p className="sname">Sushant Koul</p>
                      <p className="marks">91.6%</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s9} />
                    <div className="details">
                      <p className="sname">Shivanjaly</p>
                      <p className="marks">91%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s10} />
                    <div className="details">
                      <p className="sname">Dikshita Gupta</p>
                      <p className="marks">90.8%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s11} />
                    <div className="details">
                      <p className="sname">Tanish Garoo</p>
                      <p className="marks">90.4%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s12} />
                    <div className="details">
                      <p className="sname">Aavantika</p>
                      <p className="marks">90%</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Row>

            <Row className="resultsrow">
              <div style={{ fontSize: "1.2rem", marginTop: "2rem" }}>
                <b>Toppers: XII</b>
              </div>
              <Row>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s22} />
                    <div className="details">
                      <p className="sname">Mokshit Koul</p>
                      <p className="marks">95.6%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s23} />
                    <div className="details">
                      <p className="sname">Sania Bhat</p>
                      <p className="marks">95.6%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s24} />
                    <div className="details">
                      <p className="sname">Vardhan Raina</p>
                      <p className="marks">95.2%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s25} />
                    <div className="details">
                      <p className="sname">Sahat Bhan</p>
                      <p className="marks">93.8%</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s26} />
                    <div className="details">
                      <p className="sname">Aryan Dhar</p>
                      <p className="marks">93.4%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s27} />
                    <div className="details">
                      <p className="sname">Aahi Dhar</p>
                      <p className="marks">92.4%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s28} />
                    <div className="details">
                      <p className="sname">Arushi Kantroo</p>
                      <p className="marks">95.6%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s29} />
                    <div className="details">
                      <p className="sname">Tabeer Kour</p>
                      <p className="marks">95.2%</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s30} />
                    <div className="details">
                      <p className="sname">Shruti Mantoo</p>
                      <p className="marks">88.6%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s31} />
                    <div className="details">
                      <p className="sname">Janvi Kour</p>
                      <p className="marks">88.4%</p>
                    </div>
                  </div>
                </Col>
                <Col className="middle">
                  <div className="resultcard">
                    <img className="studentimg" src={s32} />
                    <div className="details">
                      <p className="sname">Kashish Sachdeva</p>
                      <p className="marks">88.2%</p>
                    </div>
                  </div>
                </Col>
                <Col className="hidden"></Col>
              </Row>
              <div className="d-flex justify-content-center align-items-center gap-4"></div>
            </Row>
          </Col>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Results;
