import pre_primary from "./image/pre-primary.jpg";
import primary from "./image/primary.jpg";
import secondary from "./image/secondary.jpg";
import sr_secondary from "./image/sr_secondary.jpg";
import shakunchoudhary from "./image/Shakun Choudhury.jpg"
//alumni
import abhishek from "./image/abhishek.jpg";
import prakriti from "./image/prakriti.jpg";
import karandev from "./image/karandev.jpg";
import rohan from "./image/rohan.jpg";
import anjan from "./image/anjan.jpg";
//
import JinsiPhoto from "./image/newImg/Jinsi-picture.png";
import SumantaPhoto from "./image/newImg/Sumanta.jpg";
import DebarotiPhoto from "./image/debaroti.png";
import subrata from "./image/subrata.jpg";

import slider_1 from "./image/slider_1.jpg";
import computer_lab from "./image/computer_lab.jpg";
import classroom from "./image/classroom.jpg";
//
import result_10 from "./image/result-10.jpg";
import result_10_1 from "./image/result-10-1.jpg";
import result_10_2 from "./image/result-10-2.jpg";
import result_10_3 from "./image/result-10-3.jpg";
import result_12_1 from "./image/result-12-1.jpg";
import result_12_2 from "./image/result-12-2.jpg";
import result_12_3 from "./image/result-12-3.jpg";

//
import laboratories from "./image/laboratories.jpg";
import library from "./image/library.jpg";
//
import BiologyLab from "./image/BiologyLab.jpg";
import ChemistryLab from "./image/ChemistryLab.jpg";
import PhysicLab from "./image/PhysicLab.jpg";
import danceClassroom from "./image/danceClassroom.jpg";
import paintingClassroom from "./image/paintingClassroom.jpg";
//trip
import trips1 from "./image/trips 1.jpeg";
import trips2 from "./image/trips 2.jpeg";

import life_skill_1 from "./image/life_skill_1.jpg";
import life_skill_2 from "./image/life_skill_2.jpg";
import vocational_1 from "./image/vocational_1.jpg";
import vocational_2 from "./image/vocational_2.jpg";
import skill_based from "./image/skill_based.jpg";
import skill_based_2 from "./image/skill_based_2.jpg";
import transportation from "./image/transportation.jpg";
import annual_1 from "./image/annual_1.jpg";
import educationVisit from "./image/educationVisit.jpg";
import golden from "./image/golden.png";
import election from "./image/election.jpg";
import danceday from "./image/danceday.png";
import charcha from "./image/charcha.png";
import senitization from "./image/senitization.png";
import ram from "./image/ram.png";
import secretaryVisit from "./image/secretaryVisit.png";
import parent from "./image/parent.jpg";
import annual_2 from "./image/annual_2.jpg";
import annual_3 from "./image/annual_3.jpg";
import sports_club from "./image/sports_club.jpg";
import environment_club from "./image/environment_club.jpg";
import expression_club from "./image/expression_club.jpg";
import student_council_1 from "./image/student_council_1.jpg";
import student_council_2 from "./image/student_council_2.jpg";
import student_council_3 from "./image/student_council_3.jpg";
import ncc_1 from "./image/ncc_1.jpg";
import ncc_2 from "./image/ncc2.jpg";
import school_band_1 from "./image/school_band_1.jpg";
import scouts_1 from "./image/scouts_1.jpg";
import bus_1 from "./image/bus_1.jpg";
import award_1 from "./image/award_1.jpg";
import award_2 from "./image/award_2.jpg";
import award_3 from "./image/award_3.jpg";
import award_4 from "./image/award_4.jpg";
import award_5 from "./image/award_5.jpg";
import award_6 from "./image/award_6.jpg";
import award_7 from "./image/award_7.jpg";
import award_8 from "./image/award_8.jpg";
import award_9 from "./image/award_9.jpg";
import award_10 from "./image/award_10.jpg";
import pre_primary_1 from "./image/pre_primary_1.jpg";
import pre_primary_2 from "./image/pre_primary_2.jpg";
import primary_1 from "./image/primary_1.jpg";
import secondary_1 from "./image/secondary_1.jpg";
import secondary_2 from "./image/secondary_2.jpg";
import sr_secondary_1_1 from "./image/sr_secondary_1_1.jpg";
import sr_secondary_1_2 from "./image/sr_secondary_1_2.jpg";
import sr_secondary_2 from "./image/sr_secondary_2.jpg";
import career_1 from "./image/career_1.jpg";
import career_2 from "./image/career_2.jpg";
import career_4 from "./image/career_4.jpg";
import career_5 from "./image/career_5.jpg";
import sports_1 from "./image/sports_1.jpg";
import sports_3 from "./image/sports_3.jpg";
import arts_club from "./image/arts_club.jpg";
import playground_1 from "./image/playground_1.jpg";
import vision from "./image/vision.jpg";
import mission from "./image/mission.jpg";
import anti_bullying from "./image/anti_bullying.jpg";
import health_1 from "./image/health_1.jpg";
import health_2 from "./image/health_2.jpg";
import value_club from "./image/value_club.jpg";
import social_impact from "./image/social_impact.jpg";
import gender_sensitivity from "./image/gender_sensitivity.jpg";
import english_club from "./image/english_club.jpg";
import budding_1 from "./image/budding_1.jpg";
import budding_2 from "./image/budding_2.jpg";
import sdmc from "./image/sdmc.jpg";
import sdmc_2 from "./image/sdmc_2.jpg";
import house_system from "./image/house_system.jpg";
import trip_1 from "./image/trip_1.jpg";
import trip_cover from "./image/trip_cover.jpg";
import trip_cover_1 from "./image/trip_cover_1.jpg";
import trip_4 from "./image/trip_4.jpg";
import primary_curriculum from "./image/primary_curriculum.jpg";
import interactive from "./image/interactive.jpg";
import happy_classroom from "./image/happy_classroom.jpg";
import experimental_learning from "./image/experimental_learning.jpg";
import spacious_classroom from "./image/spacious_classroom.jpg";
import spacious_classroom_2 from "./image/spacious_classroom_2.jpg";
import stem_lab from "./image/stem_lab.jpg";
import stem_lab_2 from "./image/stem_lab_2.jpg";
import computer_lab_1 from "./image/computer_lab_1.jpg";
import computer_lab_2 from "./image/computer_lab_2.jpg";
import laboratories_2 from "./image/laboratories_2.jpg";
import home_science from "./image/home_science.jpg";
import home_science_2 from "./image/home_science_2.jpg";
import arts from "./image/arts.jpg";
import arts_2 from "./image/arts_2.jpg";
import library_2 from "./image/library_2.jpg";
import cultural from "./image/cultural.jpg";
import cultural_2 from "./image/cultural_2.jpg";
import cultural_3 from "./image/cultural_3.jpg";
import cultural_4 from "./image/cultural_4.jpg";
import bio_lab from "./image/bio_lab.jpg";
import chem_lab from "./image/chem_lab.jpg";
import phy_lab from "./image/phy_lab.jpg";
import infrastructure_1 from "./image/infrastructure.jpg";
import faculty_1 from "./image/newImg/1.png";
import faculty_2 from "./image/newImg/2.png";
import faculty_3 from "./image/newImg/3.png";
import faculty_4 from "./image/newImg/4.png";
import faculty_5 from "./image/newImg/5.png";
import faculty_6 from "./image/newImg/6.png";
import aa from "./image/newImg/aa.jpeg";
import a from "./image/newImg/a.jpg";
import b from "./image/newImg/b.jpg";
import c from "./image/newImg/c.jpg";
import d from "./image/newImg/d.jpg";
import e from "./image/newImg/e.jpg";

import pre1 from "./image/newImg/Preprimary (1).jpeg";
import pre2 from "./image/newImg/Preprimary (2).jpeg";

import red from "./image/newImg/Houses (1).jpg";
import blue from "./image/newImg/Houses (3).jpg";
import yellow from "./image/newImg/Houses (4).jpg";
import green from "./image/newImg/Houses (5).jpg";

import expression from "./image/newImg/expression.png";
import ba from "./image/newImg/ba.png";

import media1 from "./image/newImg/media/media  coverage (1).jpeg";
import media2 from "./image/newImg/media/media  coverage (1).jpg";
import media3 from "./image/newImg/media/media  coverage (2).jpeg";
import media4 from "./image/newImg/media/media  coverage (2).jpg";
import media5 from "./image/newImg/media/media  coverage (3).jpeg";
import media6 from "./image/newImg/media/media  coverage (3).jpg";
import media7 from "./image/newImg/media/media  coverage (4).jpeg";
import media8 from "./image/newImg/media/media  coverage (4).jpg";
import media9 from "./image/newImg/media/media  coverage (5).jpeg";
import media10 from "./image/newImg/media/media  coverage (5).jpg";
import media11 from "./image/newImg/media/media  coverage (6).jpeg";
import media12 from "./image/newImg/media/media  coverage (6).jpg";
import media13 from "./image/newImg/media/media  coverage (7).jpeg";
import media14 from "./image/newImg/media/media  coverage (7).jpg";
import media15 from "./image/newImg/media/media  coverage (8).jpeg";
import media16 from "./image/newImg/media/media  coverage (8).jpg";
import media17 from "./image/newImg/media/media  coverage (9).jpeg";
import media18 from "./image/newImg/media/media  coverage (9).jpg";
import media19 from "./image/newImg/media/media  coverage (10).jpeg";
import media20 from "./image/newImg/media/media  coverage (10).jpg";
import media21 from "./image/newImg/media/media  coverage (11).jpeg";
import media22 from "./image/newImg/media/media  coverage (11).jpg";
import media23 from "./image/newImg/media/media  coverage (12).jpeg";
import media24 from "./image/newImg/media/media  coverage (12).jpg";
import media25 from "./image/newImg/media/media  coverage (13).jpg";
import media26 from "./image/newImg/media/media  coverage (14).jpg";
import media27 from "./image/newImg/media/media  coverage (15).jpg";
import media28 from "./image/newImg/media/media  coverage (16).jpg";
import media29 from "./image/newImg/media/media  coverage (17).jpg";
import media30 from "./image/newImg/media/media  coverage (18).jpg";
import media31 from "./image/newImg/media/media  coverage (19).jpg";
import media32 from "./image/newImg/media/media  coverage (20).jpg";
import media33 from "./image/newImg/media/media  coverage (21).jpg";
import media34 from "./image/newImg/media/media  coverage (22).jpg";
import media35 from "./image/newImg/media/media  coverage (23).jpg";
import media36 from "./image/newImg/media/media  coverage (24).jpg";
import media37 from "./image/newImg/media/media  coverage (25).jpg";
import media38 from "./image/newImg/media/media  coverage (26).jpg";
import media39 from "./image/newImg/media/media  coverage (27).jpg";
import media40 from "./image/newImg/media/media  coverage (28).jpg";
import media41 from "./image/newImg/media/media  coverage (29).jpg";
import media42 from "./image/newImg/media/media  coverage (30).jpg";

import sports_club_icon from "./image/clubs/sportsClubIcon.png";
import anti_bullying_icon from "./image/clubs/antiBullyingIcon.png";
import environment_club_icon from "./image/clubs/environmentClubIcon.png";
import health_club_icon from "./image/clubs/healthClubIcon.png";
import value_club_icon from "./image/clubs/valueClubIcon.png";
import social_impact_club_icon from "./image/clubs/socialImpactClub.png";
import communicationClub from "./image/clubs/communicationClub.png";
import gender_club from "./image/clubs/genderClub.png";
import english_club_icon from "./image/clubs/englishClub.png";

//
import orientation_1 from "./image/orientation_1.jpg";
import orientation_2 from "./image/orientation_2.jpg";
import orientation_3 from "./image/orientation_3.jpg";
import orientation_4 from "./image/orientation_4.jpg";
import book from "./image/book.jpg";
import waste from "./image/waste.jpg";
import waste_2 from "./image/waste_2.jpg";
import paper from "./image/paper.jpg";
import republic_1 from "./image/republic_1.jpg";
import republic_2 from "./image/republic_2.jpg";
import republic_3 from "./image/republic_3.jpg";
import covid from "./image/covid.jpg";
import mother from "./image/mother.jpg";
import first_aid from "./image/first_aid.jpg";
import basant_1 from "./image/basant_1.jpg";
import basant_2 from "./image/basant_2.jpg";
import pareeksha from "./image/pareeksha.jpg";
import pareeksha_2 from "./image/pareeksha_2.jpg";
import heritage from "./image/heritage.jpg";
import farewell from "./image/farewell.jpg";
import farewell_2 from "./image/farewell_2.jpg";
import environment from "./image/environment.jpg";
import mukeshThakur from "./image/mukesh thakur.jpg";
import fashion from "./image/fashion.jpg";
import fire from "./image/fire.jpg";
import donation_1 from "./image/donation_1.jpg";
import donation_2 from "./image/donation_2.jpg";

import { FaSchool } from "react-icons/fa";
//
export const sidebar_data_vision = [
  {
    title: "Vision & Mission",
    navigation: "vision-and-mission",
  },
  {
    title: "Message From Leadership",
    navigation: "message",
  },
  {
    title: "Academics & Curriculum",
    navigation: "nep",
  },
  {
    title: "Beyond Curriculum",
    navigation: "house-clubs",
  },
  {
    title: "Sports & Fitness",
    navigation: "sports",
  },
  {
    title: "Faculty & Staff",
    navigation: "faculty-staff",
  },
  {
    title: "Events & Happenings",
    navigation: "events",
  },
  {
    // title: "Parents' & Students' Voice",
    title: "Parents' Voice",
    navigation: "parents",
  },
  {
    title: "Infrastructure & Facilities",
    navigation: "infrastructure",
  },
  {
    title: "Achievements and Laurels",
    navigation: "awards",
  },
  {
    title: "News & Media Coverage",
    navigation: "news",
  },
  {
    title: "Admissions",
    navigation: "admissions",
  },
];
export const academics_sidenav = [
  {
    title: "NEP 2020 Ready School ",
    navigation: "nep",
  },
  {
    title: "Curriculum ",
    navigation: "curriculum",
  },
  {
    title: "Pedagogical Approach",
    navigation: "pedagogi",
  },
  {
    title: "Life Skill Curriculum",
    navigation: "life-skill",
  },
  {
    title: "Assessment",
    navigation: "examination",
  },
  {
    title: "Skill & Vocational Subjects",
    navigation: "skill-vocational",
  },
  {
    title: "Results",
    navigation: "results",
  },
  {
    title: "Booklist",
    navigation: "books",
  },
];
export const beyond_sidenav = [
  {
    title: "Houses & Clubs",
    navigation: "house-clubs",
  },
  {
    title: "Student Council",
    navigation: "student-council",
  },
  {
    title: "Trips, Excursions & Field Visits",
    navigation: "trips",
  },
  {
    title: "NCC, Scouts & Guides",
    navigation: "ncc-scouts",
  },
];
//
export const vision_mission = [
  {
    id: 1,
    title: "Vision",
    description:
      "Our vision at Hermann Gmeiner School is to empower students through a transformative blend of academic excellence, innovation, and holistic development. We aspire to create a dynamic learning environment where education is furthermore enriched by technology and creativity. By nurturing each child's unique potential, we aim to develop lifelong learners who are not only academically accomplished but also socially responsible, compassionate, and prepared to lead in a rapidly changing world. Our ultimate goal is to inspire our students to become confident, ethical, and engaged citizens who contribute positively to society.",
    description_image: [vision],
  },
  {
    id: 2,
    title: "Mission",
    description:
     "Our mission at Hermann Gmeiner School is to bring our vision to life by delivering a well-rounded, future-focused education. We are dedicated to fostering academic excellence through a modern curriculum that integrates technology, creativity, and experiential learning. By providing opportunities in sports and co-curricular activities, we aim to develop students' physical, emotional, and social well-being. We commit to cultivating an inclusive and nurturing environment where educators and students collaborate to enhance learning experiences. Through personalized guidance, diverse learning approaches, and character-building initiatives, we empower our students to grow into responsible and compassionate citizens." , 
    description_image: [mission],
  },
];
export const message_data = [
  {
    id: 1,
    title: "President",
    name: "Rakesh Jinsi",
    occ: "SOS Children’s Villages of India",
    image: JinsiPhoto,
    description:
      "Rakesh Jinsi, an engineer by degree, has 41 years of experience in leadership roles at several institutions, including SOS Children's Villages. Presently running Khushboo, a daycare centre for children with severe mental and multiple disabilities, he has always been active in national level bodies such  as the National Human Rights Commission and Voluntary Action Network of India (VANI), the Apex body of the NGO sector.",
    extra_description: [
      `"I firmly believe that each and every child deserves an education that is advanced, detail-oriented yet nurturing and holistic. As President of SOS Children’s Villages of India, I wish to provide the best possible learning experience to the future leaders of our country, so that all children are able to reach their maximum potential and achieve their dreams. Students end up spending most of their childhood within classrooms, so it is essential that within those walls they are imparted proper theoretical knowledge, practical aptitude, communal values, essential life skills, and an understanding of how the world works and their place in it. ,
      "Adequate grooming and learning of children at a young age leads to them being strong, confident, hard-working and intelligent individuals, attributes that I deem necessary for the new era of technology and scientific progress we are heading towards."`,
    ],
  },
  {
    id: 2,
    title: "Secretary General",
    name: "Sumanta Kar",
    occ: "SOS Children’s Villages of India",
    image: SumantaPhoto,
    description:
      "Education at Hermann Gmeiner Schools has always meant the Holistic Development of a student – we believe that each child is entitled to the best possible skill set for their future, therefore, each child entrusted with us will receive an education that is in its finest form. ",
    extra_description: [
      "Education is preparation for life, it should be an enjoyable, interactive and ongoing process to make the students confident young adults, sensitive to global issues, committed citizens who are aware of the happenings of the world and sensible individuals with a positive attitude. ",
      "Students should have a strong character and a good understanding of their humane and emotional sides. All our Hermann Gmeiner Schools have been actively engaged in relating to, understanding and working with communities around our schools.",
      "Safeguarding of children remains a top priority and they are nurtured through a theme-based curriculum and actively engage in experiential learning. Our teachers and principals play a pivotal role in the development of each child. Our alumni are our brand ambassadors, who leave a lasting footprint in whatever profession they choose to pursue. Each student who joins us becomes a future world leader, ready to bring progressive change and make our nation proud.",
    ],
  },
  {
    id: 3,
    title: "Head- Education & Child Development",
    name: "Debaroti Bose",
    occ: "SOS Children’s Villages of India",
    image: DebarotiPhoto,
    description:
      "I believe that school should be the experience of a lifetime. Our school experience sets the stage for success later in life and provides a lifetime of positive memories for our students. We are proud of our continuous goal of providing an excellent education for our students.We have a dynamic, hardworking faculty who are involved in education to benefit the students.The entire gamut of school education is transforming the teaching-learning process. ",
    extra_description: [
      "In the modern concept of schooling, teaching has become redundant, paving the way for the facilitation of learning through knowledge sharing. The wave of digital learning and cloud computing is reaching new heights.",
      " In all this whirlwind of new-age technology, it is important to charge ahead but with one’s head firmly on one’s shoulders. Hermann Gmeiner School strives to inculcate moral values, manners, responsibilities among students so that they may build a world free of all the evils.",
      "Students here are encouraged to dream, groomed to give wings to their dreams, to take off and explore the unexplored. The plethora of interesting & exciting opportunities available to youngsters today often leave them with an array of things to do.",
      " It is important therefore to help them build their focus on the subject that draws them most and gives shape to their vision. A student is made to blossom intellectually, morally, and spiritually. As Swami Vivekananda said, “Take up one idea. Make that one idea your life – think of it, dream of it, and live on that idea. Let the brain, muscles, nerves, every part of your body, be full of that idea, and just leave every other idea alone. This is the way to success.”",
    ],
  },
  {
    id: 4,
    title: "Principal",
    name: "Ms. Shakun Choudhary",
    occ: "Hermann Gmeiner School, Jammu",
    image: shakunchoudhary,
    description: `
    “योग: कर्मसु कौशलम्”\n
    (Yogah Karmasu Kaushalam)\n
    This beautiful shlok from the Bhagavad Gita reminds us that true excellence lies in performing our duties with dedication and skill.
    `,
    extra_description: [
    `  As I step into the role of Principal at Hermann Gmeiner School, I carry this wisdom with me, committed to nurturing an environment where every student can thrive academically, emotionally, and morally.

My vision for Hermann Gmeiner School is one where holistic development is at the core of our educational approach. We aim to provide a dynamic and balanced environment that cultivates intellectual curiosity, creativity, physical well-being, and spiritual growth. Our mission is to empower students with the 21st-century skills they need to succeed in a rapidly changing world—critical thinking, collaboration, communication, and creativity—while instilling values of integrity, empathy, and resilience.

Academic excellence is the foundation of our school’s legacy, and we will continue to uphold high standards in teaching and learning. We are committed to offering a rigorous curriculum that challenges students to think independently and solve problems innovatively. However, our focus is not just on academics; we are dedicated to providing ample co-curricular opportunities in sports, arts, music, and community service, enabling students to explore their talents, develop leadership skills, and build character.

At Hermann Gmeiner School, we believe that true education goes beyond textbooks and classrooms. We strive to nurture well-rounded individuals who are physically active, emotionally intelligent, and socially responsible. Leadership development is a critical component of our approach, as we aim to create future leaders who are not only academically competent but also compassionate and ethical.

We will ensure that every student has access to opportunities that allow them to grow into confident and capable individuals, ready to take on challenges with courage and creativity. Whether it’s through leadership roles in student governance, participating in sports, or engaging in community initiatives, our students will be equipped with the tools they need to lead and inspire others.

At Hermann Gmeiner School, we aim to provide a platform where every child can discover their passion, hone their skills, and develop the confidence to pursue their dreams. We are committed to creating an inclusive environment where all students, regardless of background, feel valued and are given the support they need to reach their full potential.

Together, with the support of our dedicated teachers and parents, I am confident that we will take Hermann Gmeiner School to new heights of success. Let us work in unison to create a vibrant, caring, and innovative learning community where every child is encouraged to become the best version of themselves.

“कर्मण्येवाधिकारस्ते मा फलेषु कदाचन”
(Karmanye Vadhikaraste Ma Phaleshu Kadachana)
Do your duty without attachment to the results—another guiding principle from Lord Krishna that inspires us to focus on the journey of learning and growth rather than just the outcomes.

      `
    
   
    ],
  },
];
export const infrastructure = [
  {
    id: 1,
    title: "Infrastructure",
    description: `Hermann Gmeiner School, Jammu is one of the chains of International schools spread all over the world, providing quality & meaningful education to children in an atmosphere of freedom and social unity, helping create a whole human being & a citizen for a highly dynamic & competitive society. It is a community project of SOS Children’s Villages.
    The architecture of the building is a virtual treat, and a feast to the eyes of the visitors. It is spread over 16 acres with a lush green & pollution-free environment located near Revenue Complex, Gol Gujral, Talab Tillo, Jammu.
    The School is established to cater to the needs of SOS Children’s Village of India & to provide quality education to the children of Jammu.
    `,
    description_image: [slider_1],
  },
  {
    id: 2,
    title: "Spacious Classrooms",
    description: `The school has well-equipped interactive and spacious classrooms. It serves as a valuable educational aid and multimedia facility acting as a catalyst in not only creating interest but also increasing understanding of the students in the basic concepts of various disciplines. Hermann Gmeiner School, Jammu is associated with NIIT Nguru solutions to support teacher development by enhancing their professional expertise and making them well-versed, especially in the field of Information and Communications Technology (ICT). It allows teachers to pedagogically apply relevant ICT into the curriculum to enhance the overall learning experience for students.`,
    description_image: [classroom],
  },
  {
    id: 3,
    title: "Computer Lab",
    description: `The school has a highly sophisticated computer lab. It is equipped with a computing facility with 40 i3 computers connected through the Local Area Network as well as Wifi. The high-speed internet connectivity of 11 Mbps (24*7) to access vast intellectual resources. These facilities are an integral part of the curriculum. Students are expected to use computers in their day-to-day studies to meet the challenges of modern learning and to keep pace with the global scenario `,
    description_image: [computer_lab],
  },
  {
    id: 4,
    title: "Biology Lab ",
    description: `Students are encouraged to learn through experiments and grasp diverse ideas beyond their textbooks in the Biology lab, which is separate from traditional classroom instruction. Experiments and observations make learning more engaging and enjoyable. Experiments are fun for students because they learn something new from the outcomes. Every student from Grade 1 to 12 has the opportunity to do the practical under the supervision of the professors.`,
    description_image: [BiologyLab],
  },
  {
    id: 5,
    title: "Chemistry Lab ",
    description: `Hermann Gmeiner School has a fantastic Chemistry lab with all of the latest and best-in-class apparatus and chemicals, as well as a wonderful learning atmosphere where students do practicals under the supervision of the lecturers. All precautions are followed, and the youngsters learn the fundamentals of chemistry by doing their own experiments, observing, and drawing conclusions.
    `,
    description_image: [ChemistryLab],
  },
  {
    id: 6,
    title: "Physics Lab",
    description: `Physics lab of Hermann Gmeiner School has all the up-to-date equipment. This lab assists students in developing a broad range of basic experimental physics and data analysis skills and tools. It is also intended for each student to conduct experiments under the supervision of Physics lecturers. The whole operation of the Physics lab is conducted in accordance with established safety standards.`,
    description_image: [PhysicLab],
  },
  {
    id: 7,
    title: "Art & Craft Room (The Heart of Creativity)",
    description: `The Art Room fosters the creative and intellectual development of the students through quality visual arts programs, facilitated by the arts faculty. The art room drives each child to be curious and original in their work while they study a variety of Art forms such as 2D, 3D, and installations by exploring mediums like acrylic, charcoal, water, dry pastels, oil pastels, pencil coloring, clay, wires, papier mache, etc.`,
    description_image: [paintingClassroom],
  },
  {
    id: 8,
    title: "Library",
    description: `The serene ambiance inspires a love for reading. Information at the click of a fingertip invites young users to delve into the magical world of words and the wealth of research material available. The school has a well-equipped library. The Library resources comprise Encyclopaedias, Dictionaries on various subjects, general collections, valuable sourcebooks, critical works as well as specific skills-related books on Personality Development, Time Management, and Communication Skills. The Library subscribes to research e-journals, besides subscribing to daily newspapers, magazines, and journals.
    `,
    description_image: [library],
  },
  {
    id: 9,
    title: "Cultural Center (Music & Dance Room)",
    description: `This is the place where young musicians start their journey to become great composers & where the children unwind and learn the aesthetics of movement. Classical Dance (Indian & Western), Modern Dance (Indian & Western), Choreography, Direction, Vocal Music (Indian and Western), Instrumental Music (Indian and Western) are all interwoven into the fabric of our educational experience. The Department of Music and Dance is committed to excellence, innovation and learning in action.`,
    description_image: [danceClassroom],
  },
  {
    id: 10,
    title: "Playground",
    description: `Games & sports form an integral part of the education and a great emphasis is laid on this aspect by Hermann Gmeiner School Jammu. We have ample space for carrying out every indoor and outdoor activity like:`,
    features: [
      "Cricket",
      "Basketball",
      "Chess",
      "Hockey",
      "Football",
      "Carrom",
      " Table Tennis",
      "Badminton",
      "Volleyball",
      "Judo",
      "Kho-Kho",
      "Dodging Ball",
      "Jumps",
      "Sprints",
      "Discuss Throw",
      "Karate",
      "Kabaddi",
      "Long Distance Races",
    ],
  },
  {
    id: 11,
    title: "Transportation",
    description: `The school provides an optional facility for students to commute from home to school. This service is offered from various places of Jammu to the school. The buses are operated by experienced & trained drivers/attendants to monitor the children’s safe journey. The school tries to provide safe and comfortable commuting for students from home to school and back. Our buses are specially equipped and fitted with speed governors, rear cameras, first-aid equipment, fire extinguishers, etc.`,
    description_image: [transportation],
  },
];
export const career_guidance = [
  {
    id: 1,
    title: "Career Counselling Workshop",
    description: `The future depends on what you do today." Hermann Gmeiner School organised a Career Counseling and Guidance programme to direct the students to move on the right path. Parents from different fields and resource persons were invited to share their journey of success with students so that they could learn different aspects of success. The students engaged in the session, asked queries to have better vision for their future and were satisfied with the guidance.`,
    description_image: [career_1],
  },
  {
    id: 2,
    title: "",
    description: `Career building is a deep rooted process. It becomes tedious for adolescents to choose an appropriate career with great placements and a bright future. School plays an important role in this ever-growing process of finding oneself and establishing oneself.`,
    description_image: [career_2],
  },
  {
    id: 3,
    title: "",
    description: ` ITM Institute of Design & Media conducted a career counselling workshop on career opportunities in online world like “YOUTUBE, VFX, ANIMATION", etc. on Feb 16th ,2022. The key speaker was Ms. Jyotsna Raghunathan, who is a storyteller and a film maker with over 20 years of experience in writing scripts, directing films and working in the educational sector of media and mass communication. She has also specialized in curriculum design and development, as well as making films and writing scripts under her own company banner, Panvision. `,
    description_image: [career_4],
  },
  {
    id: 4,
    title: "",
    description: ` The workshop started with her addressing the issue of confusion being created in youngsters' mind while choosing a career. She talked about the varied courses available for all the streams and how these can be helpful in fulfilling their dreams. The students were attentive and interactive and asked queries like the exams to be taken up, combinations of subjects available, options offered, placements etc. It was an enriching session for the students of grade XII.`,
    description_image: [career_5],
  },
];
export const admissions = [
  {
    id: 1,
    title: "Admission Procedure",
    description: `Admission to the school is made without any distinction of religion, race, caste, place of birth, etc. Regards reservation for SC/ST, etc. students; it is governed by the Education Act read in connection with the rules on the subject applicable in the state of Jammu. Normally admission is granted only to nursery and kindergarten classes for which the child should be three years as of 1st April at the time of admission, but should not be over 4 years on this date.`,
  },
  {
    id: 2,
    title: "Documents to be Attached",
    description: ``,
    features: [
      "Birth certificate for kindergarten",
      "School leaving certificate from the school last attended",
      "Address proof (Ration card/ Phone Bill/Aadhaar card etc.)",
      "Medical Fitness certificate from a doctor",
      "Registration with CBSE in case admission is sought in classes X and XII",
      "EWS / BPL certificate if available",
    ],
  },
  {
    id: 3,
    title: "Rejoining School after Absence",
    description: `For admission in a class, XI counseling will be conducted for Hermann Gmeiner School students, and other students' entrance tests will be conducted in the subject offered, subject to the availability of vacancies. Admissions to X and XII are strictly governed by CBSE norms. Admission to other classes shall purely depend on the vacancy and merit of the student. No claim for the seat can be made, whatsoever admission will be granted strictly on the merit of qualified candidates.
    `,
    features: [
      "A student who remains absent for more than 10 days without prior information may get his/her name struck off from school rolls.",
      "The student will only be permitted to attend classes following an absence from the school for any reason including illness if they bring an application from the parent /guardian or a proper medical certificate from the qualified doctor if under medical treatment.",
      "In all other cases, the child is readmitted with a payment of 50% of the admission fee.",
    ],
  },
  {
    id: 4,
    title: "Admission Criteria",
    description: `Admissions to X and XII are strictly governed by CBSE norms. Admission to other classes shall purely depend on the vacancy and merit of the student. No claim for the seat can be made, whatsoever admission will be granted strictly on the merit of qualified candidates.`,
    features: [
      "Admission will be granted to parents, who are in service, Govt. Employee, Bank employee, working in Public Sector Units (We should avoid giving admissions to daily wagers)",
      "Monthly income of parents should be a minimum of INR 50,000/- P.M.",
      "Interviewing parents is mandatory. Interaction of the child with the specific class teacher is mandatory.",
      "Any one of the parents should be educated having minimum qualifications as ‘Graduate’ or equivalent. In case a parent is a landlord/ farmer, he has to produce the income certificate or any other document from a competent authority to show his/her monthly income as minimum 50,000/- P.M.",
      "Admission to the school is made without any distinction of religion, race, caste, place of birth, etc.",
      "Admission is granted as and when vacancies are available in any class.",
    ],
  },
  {
    id: 5,
    title: "Admission Committee",
    description: ``,
    features: [
      "Class Teacher (for interaction with the child)",
      "Written test as per grade level",
      "Headmistress / HeadMasters and Counsellor (First interaction of the parents and student).",
      " Final approval to be given by the Principal after interaction with the child and the parents with verification of all documents.",
      "Class VI onwards, final approval to be given by Head ECD.",
    ],
  },
  {
    id: 7,
    title: "Admission to Class XI",
    description: `The entrance test will be conducted in the basic subjects of Class X studied by the student (subject to the availability of vacancy).
    To be eligible for admission the child should get at least 65% in our school entrance exam. Students who qualified for their Class X from a recognized state board are eligible for the examination. However, it is mandatory to score the minimum percentage to be eligible for the stream chosen.
    `,
  },
  {
    title: `For admission to Science Stream with Mathematics`,
    features: [
      "A minimum of 65% in Mathematics Standard Paper (New Admissions)",
      "A minimum of 65% in Science (New Admissions)",
      "Aggregate marks in 5 subjects including Mathematics & Science must be 65% (New Admissions)",
      "For internal admissions, aggregate should be 60%.",
    ],
  },
  {
    title: `For admission to Science Stream without Mathematics`,
    features: [
      "A minimum of 65% in Science (New Admissions)",
      "Aggregate marks in any  five subjects including Mathematics & Science must be 65% (New Admissions)",
      "For internal admissions aggregate should be 60%",
    ],
  },
  {
    title: `For Commerce Stream`,
    features: [
      "A minimum of 55% aggregate marks in any 5 subjects",
      "Mathematics can be offered if the student has obtained a minimum of 60% marks in Mathematics standard paper",
    ],
  },
  // {
  //   title: `For Humanities Stream`,
  //   features: [
  //     "A minimum of 55% aggregate marks in any 5 subjects ",
  //     "A minimum of 55% in Social Studies",
  //   ],
  // },
  {
    id: 10,
    title: "Admission to Class IX",
    description: `For admission in Class IX, the student must have finished his/her regular study in class VIII. The admission process will be conducted only for the students who were studying in any institution affiliated by state governments or UT governments or other certified boards.`,
  },
  {
    id: 11,
    title: "Admission to Classes Nursery to VIII",
    description: ``,
    features: [
      `Admission to Nursery: Students must complete 3+ years of age as of 31st March of the academic year.`,
      `Admission to Class I: Students must complete 6+ years of age as of 31 March, of the academic year.`,
      `Admission to classes II-VIII: Report Card of the child has to be submitted with an equivalent of at least 60% in previous classes. TC to be collected from every child and the reason for shifting/transfer to be verified.`,
    ],
  },
  {
    id: 12,
    title: "",
    description: `Those seeking admission should produce the following:`,
    features: [
      "Birth Certificate",
      "A School Leaving Certificate from the school last attended.",
      "Progress Report of the child indicating academic performance and participation in co-curricular activities in the school last attended.",
      "Registration with CBSE, in case, admission is sought in Class X and XII on the genuine transfer of parents in addition to (2) & (3) above.",
      "4 Passport size photographs",
      "Address Proof",
      "Medical Certificate",
      "SC/ST/EWS/BPL Certificate as per the process and rules of the schools.",
    ],
  },
  {
    id: 13,
    title: "Admission Approval",
    description: ``,
    features: [
      "The Principal & Admission Committee together will give the final approval of admission.",
      "Class VI onwards, all admissions to be approved by Head ECD.",
    ],
  },
];

export const curriculum = [
  {
    title: "Primary Curriculum",
    navigation: "primary",
    text: "Designed to nurture the child in all dimensions of his or her life",
    image: primary,
  },
  {
    title: "Secondary Curriculum",
    navigation: "secondary",
    text: "A skills-focused programme that provides students with a broad and balanced knowledge of key subjects",
    image: secondary,
  },
  {
    title: "Senior Secondary Curriculum",
    navigation: "sr-secondary",
    text: "Encompassing learning areas to foster competences in our students",
    image: sr_secondary,
  },
];
export const pre_primary_data = [
  {
    id: 1,
    description:
      "Pre-Primary is the foundational step of education where the learning is imparted to the age group of 3 to 6 years. The curriculum is a blend of both Experiential and Project based learning where the students have hands on experience of the learning resulting them to retain the learning lifelong. We want children to interact with each other and explore various aspects of life which includes taking care of themselves, awareness towards environment, proper physical/mental growth and development, use of mathematical concepts in day-to-day life experiences.",
    description_image: [pre1],
  },
  {
    id: 2,
    title: "",
    description:
      "The benefits of such deep learning at this stage are memory retention, life-skill development and emotional growth. Activity based and Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected together and integrated with a thematic approach making teaching and learning active and interactive. The starting ground is the uniqueness of the child, active learning, and integration in a group and a collective partnership. This way learning becomes meaningful and a child makes deeper connection with the world.",
    description_image: [pre2],
  },
];
export const primary_data = [
  {
    id: 1,
    title: "Pre-Primary Curriculum",
    description:
      "Pre-Primary is the foundational step of education where the learning is imparted to the age group of 3 to 6 years. The curriculum is a blend of both Experiential and Project based learning where the students have hands on experience of the learning resulting them to retain the learning lifelong. We want children to interact with each other and explore various aspects of life which includes taking care of themselves, awareness towards environment, proper physical/mental growth and development, use of mathematical concepts in day-to-day life experiences.",
    description_image: [pre1],
  },
  {
    id: 2,
    title: "",
    description:
      "The benefits of such deep learning at this stage are memory retention, life-skill development and emotional growth. Activity based and Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected together and integrated with a thematic approach making teaching and learning active and interactive. The starting ground is the uniqueness of the child, active learning, and integration in a group and a collective partnership. This way learning becomes meaningful and a child makes deeper connection with the world.",
    description_image: [pre2],
  },
  {
    id: 1,
    title: "Primary Curriculum",
    description:
      "The primary curriculum is a blend of both Experiential and Project-based learning where the students have hands-on experience of the learning resulting in them retaining the learning lifelong. Theme curriculum is the focus of the foundational classes where many areas of the curriculum are connected and integrated with a thematic approach making teaching and learning active and interactive.",
    description_image: [primary_curriculum],
  },
  {
    id: 2,
    title: "Interactive Classes ",
    description:
      "Interactive classes encourage students’ participation and engagement for better learning and overall development of a child. As the primary classes are the foundational years of education, Hermann Gmeiner School has well-equipped Interactive board classrooms, open play areas, activity corners, reading corners, etc. to reduce dependency on textbooks and increase focus on learning by interacting. Students do a lot of activities like making floor games to learn tables and other numeracy-related topics. The children take part in poem recitations, debates, speeches, and group discussions which make them more confident and learn from their peer group.",
    description_image: [interactive],
  },
  {
    id: 3,
    title: "  Happy Classrooms",
    description:
      "Three to seven years are crucial in everyone’s life, here learning grows through analyzing and evaluating an issue, and when it is done through activities and play the way, the method then creates happy classrooms. Learning is done including creativities and activities for the students, making them happy in the course of learning. Students do recreational activities like drawing, painting, making postcards, puppetry, and showcase their talent through ME time.",
    description_image: [happy_classroom],
  },
];
export const secondary_data = [
  {
    id: 1,
    rightImg: "true",
    right_img: [sr_secondary_1_1],
    description: `At this stage, students have the choice of a curriculum leading to the All India Secondary School Examination conducted by the Central Board of Secondary Education (CBSE), New Delhi. At Secondary school we offer:`,
    features: [
      "Languages (English & Hindi)",
      "Science",
      "Mathematics",
      "Social Studies",
    ],
    features_image: [secondary_1],
  },
  {
    id: 2,
    title: "",
    description:
      "The secondary curriculum is aimed for the holistic development of students so that they develop effective communication and critical thinking skills. The syllabus is devised in a manner which ensures the students strengthen their work habits.The subjects chosen in this curriculum prepares the students for further studies.",
  },
];
export const sr_secondary_data = [
  {
    id: 1,
    description: `The Senior Secondary School curriculum further builds on the skills and knowledge acquired by the students in the earlier classes. Life skills like social skills, thinking skills – critical, creative, decision making and problem-solving, emotional skills, and values are embedded in the curriculum. The school offers all three streams at the Senior Secondary level- Science (Medical and Non-Medical), Commerce, and Humanities.`,
    description_image: [sr_secondary_1_2],
  },
  {
    id: 2,
    title: "",
    description: `A combination of subjects helps the students in their niche and areas they want to particularly focus on. It helps them to filter out the vast range of available opportunities. Professional guidance and counselling are very crucial at this stage. The school trains its staff according to the needs and demands of the children.
The school offers academics with a subject combination as: `,
  },
  {
    description: "srsec",
  },
];
// export const alumni_data = [
//   {
//     id: 1,
//     title: "Nikhel Bakshi",
//     occupation: "Youngest Chief Flying Instructor & Trainer",
//     description: `Youngest Chief Flying Instructor & Trainer
//     I got the privilege of being a part of Hermann Gmeiner School, where I not only grew physically but also emotionally and psychologically. The lessons and values taught here are embedded deep into my mind and are a part of my personality. I will cherish the experiences and memories of the school and hon'ble teachers forever. From 2006 - 2016 (10 years), I have been the Youngest Chief flying Instructor/Trainer and have trained more than 300 pilots who are now flying in different airlines in India and in foreign airlines as well. From 2017 till date, I am working as a Captain in Indigo Airlines and holding a management post as well as that of a Fleet Coordinator (Delhi Base).
//     `,
//     image: nikhel,
//   },
//   {
//     id: 2,
//     title: " Ankita Lal",
//     occupation:
//       "Additional Senior Civil Judge in Saket District Courts, New Delhi.",
//     description: `Additional Senior Civil Judge in Saket District Courts, New Delhi.
//     School plays a very important part in our lives. What we are today is because of what we have learnt from our schools. I feel blessed being a part of Hermann Gmeiner School Faridabad. The school gave me an environment which helped me excel in my academic goals. The teachers were more helpful than I had ever expected. Not only did the school cater perfectly to my academic needs but also helped me build my personality and co-curricular skills.
//     `,
//     image: ankita,
//   },
//   {
//     id: 3,
//     title: "Sandeep Kak",
//     occupation: "General Manager - Otis Elevators",
//     description: `General Manager - Otis Elevators (Bangalore, Karnataka)
//     The School is a stepping stone, responsible for the overall development of a naive and innocent child into a responsible, sensible, and law-abiding citizen. It is a home away from home for all its students and for me too, Hermann Gmeiner School was my extended family for five years. The school was responsible for shaping my character and personality. At Hermann Gmeiner School, I and most of my peers have always felt that teachers were our friends, philosophers, and guides. They cared about every student & always tried to give the best advice.
//     `,
//     image: sandeep,
//   },
//   {
//     id: 4,
//     title: "Bharat Bansal",
//     occupation: "Entrepreneur in the USA",
//     description: `Entrepreneur in the USA
//     Hermann Gmeiner School has been my alma mater for a long time. I have been extremely lucky to have studied here and have made lifelong friends in this school. FIGS has given a very unique perspective to me and my batch mates, due to its unique setting & approach to education in both curriculum and life skills. I wish the school all the best and will also be available to mentor any junior students if needed.
//     `,
//     image: bharat_bansal,
//   },

//   {
//     id: 5,
//     title: "Subhadeep Sanyal",
//     occupation: "Vice President - Omnivore Partners",
//     description: `Vice President - Omnivore Partners
//     I earned a B.Sc. (Hons) in Agriculture from CCS HAU, Hisar where I was the batch topper (2005-09). I completed my Masters degree from IIM Ahmedabad, where I specialized in agribusiness during the PGDM- ABM (2009-11). The reason why I had chosen Hermann Gmeiner School over other schools in Faridabad was due to the ethos which the school had and still continues to have. The open culture and sense of collaboration among students and faculty, complete sense of freedom for a child's overall growth and the need to be rooted to one's core values were probably the greatest takeaways for me from the school.
//     `,
//     image: subhadeep,
//   },

//   {
//     id: 6,
//     title: "Karan Babbar",
//     occupation: "Product Consultant - Accenture Service Private Limited ",
//     description: `Product Consultant - Accenture Service Private Limited
//     Excellent education is one where a child's interests are nurtured and built upon. Hermann Gmeiner School tries to put in all their efforts in imparting excellent education to all the students. It's not only about academics but the extracurricular activities are done in the school to ensure the overall development of a child. During my school days, I was much fascinated by computers and our teachers helped me in building interest by exposing me to different technologies. This exposure helped me in pursuing B. Tech in Computer Science.
//     `,
//     image: karan,
//   },
//   {
//     id: 7,
//     title: "Mr. Ajay Baisla",
//     occupation: "Municipal Councilor",
//     description: `Product Consultant - Accenture Service Private Limited
//     Excellent education is one where a child's interests are nurtured and built upon. Hermann Gmeiner School tries to put in all their efforts in imparting excellent education to all the students. It's not only about academics but the extracurricular activities are done in the school to ensure the overall development of a child. During my school days, I was much fascinated by computers and our teachers helped me in building interest by exposing me to different technologies. This exposure helped me in pursuing B. Tech in Computer Science.
//     `,
//     image: aa,
//   },
//   {
//     id: 8,
//     title: "Dr.Rajeev Arora",
//     occupation: "Specialised Dentist and Senior lecturer.",
//     description: `Specialised Dentist andSenior lecturer at Manav Rachna Dental College. Served as Hony. Secratary  of Indian Dental Association Faridabad in 2012  & 2013 and as President in 2015.
//     `,
//     image: a,
//   },
//   {
//     id: 9,
//     title: "Anchal Gulati",
//     occupation: "DD News Anchor",
//     image: b,
//   },
//   {
//     id: 10,
//     title: "Jeevant",
//     occupation: "Professional Photographer",
//     image: c,
//   },
//   {
//     id: 11,
//     title: "Bhushan Sachdeva",
//     occupation: "Chartered Accountant",
//     image: d,
//   },
//   {
//     id: 12,
//     title: "Devender Chaudhary",
//     occupation: "Co convener urban local body",
//     image: e,
//   },
// ];
export const admissions_data = [
  {
    id: 1,
    title: "Admission Criteria",
    description: `Admission to the school is made without any distinction of religion, race, caste, place of birth, etc. As regards reservations for SC/ST etc. students, it is governed by the Education Act read, in accordance with the rules on the subject as applicable in the state, organized before each technical examination. Admission is granted as and when vacancies are available in any class. `,
    image: false,
  },
  {
    id: 2,
    title: "",
    description: `Admission forms are available in the school office from the 15th March depending upon the vacancy in different classes. All admissions close by 31st July. Transfer cases from other states can be considered against vacancy even during the running session. For admission in class IX, the entrance exam will be of 2.5 hours in the subjects of English, Mathematics, and Science.
    `,
    image: false,
  },
  {
    id: 3,
    title: "",
    description: `For admission in a class, XI counseling will be conducted for Hermann Gmeiner School students, and other students' entrance tests will be conducted in the subject offered, subject to the availability of vacancies. Admissions to X and XII are strictly governed by CBSE norms. Admission to other classes shall purely depend on the vacancy and merit of the student. No claim for the seat can be made, whatsoever admission will be granted strictly on the merit of qualified candidates.
    `,
    image: false,
  },
  {
    id: 4,
    title: "",
    description: `Transfer cases from other states can be considered against vacancy even during the running session. For admission in class IX, the entrance exam will be of 2.5 hours in the subjects of English, Mathematics, and Science.
    For admission in a class, XI counseling will be conducted for Hermann Gmeiner School students, and other students' entrance tests will be conducted in the subject offered, subject to the availability of vacancies.`,

    image: false,
  },
  {
    id: 5,
    title: "",
    description: `Admissions to X and XII are strictly governed by CBSE norms. Admission to other classes shall purely depend on the vacancy and merit of the student. No claim for the seat can be made, whatsoever admission will be granted strictly on the merit of qualified candidates.`,
    image: false,
  },
  {
    id: 6,
    title: "",
    description: `Admission to Nursery- Students must complete 3+ years of age as of April 1, of the academic year.
    Admission to Class 1- Students must complete 5+ years of age as on April 1, of the academic year.
    Admission to LKG- 30% of the seats in class LKG is reserved for BPL card holders/ EWS (Economically weaker section).
    Admission in classes PREP to VIII will be done on a ‘first come first served’ basis. The Principal's or Admission Committee’s decision will be final and binding with regard to all admissions.`,
    image: false,
  },
  {
    id: 7,
    title: "",
    description: `Those seeking admission should produce the following:`,
    image: false,
    features: [
      "Birth certificate",
      "School leaving certificate",
      "Progress card of the previous class",
      "EWS/BPL card if applicable",
      "Address Proof",
      "Character Certificate",
    ],
  },
  {
    id: 10,
    title: "Mode of Payment of Fees",
    description: `Each parent will be provided with a fee Booklet containing Challan for deposit of fees at the school counter. The fee will be paid monthly within 10 days of the beginning of the month at the UCO bank counter, opposite sector 28, Mathura Road. Parents must mention the student's name, admission number, and class with a section at the back of the cheque. In case the fees are not deposited by the due date the same can be paid at the school counter in cash only.`,
    image: false,
  },
  {
    id: 11,
    title: "",
    description: `The fee without late fee is payable till the 21st of the first month at the school fee counter. In case the cheque is dishonored, the payment of Rs 200/- shall be charged extra from the parents. The due fee will have to be paid as long as the pupil’s name is on the rolls.`,
    image: false,
  },
  {
    id: 12,
    title: "",
    description: `Only those pupils who have paid all school dues are permitted to appear for the promotion examination. Parents must clear all fees dues by 31st January to avoid any inconvenience. Admission and Annual Fees will not be refunded if a pupil is withdrawn from the school. Security deposit will be refunded only on production of the receipt copy of the Depositor’s portion of the Fee Challan.`,
    image: false,
  },
  {
    id: 13,
    title: "",
    description: `Students' admission numbers should be written in legible handwriting at the time of depositing the fees. Fees lost on account of the wrong entry of name or admission number shall be the responsibility of the parent and in such a case the student's name shall fall in the defaulter list.`,
    image: false,
  },
];
export const facilities_data = [
  {
    id: 1,
    title: "Spacious Classrooms",
    description: `The School can boast spacious, well-ventilated, and excellently furnished classrooms to make the students learning comfortable and enjoyable. Individual tables and chairs are provided to students to make them independent and self-reliant. These specially designed tables and chairs can be brought together very easily to form circles and semicircles while the lesson is in progress to have group discussions and activities.`,
    image: [spacious_classroom],
  },
  {
    id: 2,
    title: "",
    description: `To avoid carrying heavy school bags,  a special storage facility is provided for every student in the classroom. In all classrooms, display boards are provided to display creative chart works, drawings and paintings, and craftworks. The school has the best infrastructure catering for today’s learning environment – like smart interactive classrooms with ICR facilities and the use of smart board technologies for conducive learning. This facilitates comprehensive learning and makes it enjoyable and an interesting process of learning.
    `,
    image: [spacious_classroom_2],
  },
  {
    id: 3,
    title: "STEM Lab",
    description: `STEM education experiences are made available in a variety of settings by the school as a way of fostering a diverse STEM workforce. Educators are focused on improving science and mathematics instructions employing several approaches to K-12 STEM education. Students also were given opportunities to spend time with professionals in STEM fields. 
    `,
    image: [stem_lab],
  },
  {
    id: 4,
    title: "",
    description: `STEM is an abbreviation that stands for 4 closely related subjects that are Science, Technology, Engineering and Mathematics. These four theoretical and practical subjects are taught together under STEM education and starting from basics to giving precise knowledge of the subjects, professional guidelines are available at the school’s STEM lab. The students are taught by giving them real-life world problems and they are to solve these problems by theoretical and practical approach. 
    `,
    image: [stem_lab_2],
  },
  {
    id: 5,
    title: "Computer Lab",
    description: `The school has a highly sophisticated state-of-the-art computer lab. It is equipped with a computing facility with i3 computers connected through the Local Area Network as well as Wifi. The high-speed internet connectivity of 11 Mbps (24*7) to access vast intellectual resources. These facilities are an integral part of the curriculum.
    `,
    image: [computer_lab_1],
  },
  {
    id: 6,
    title: "",
    description: `Students are expected to use computers in their day-to-day study to meet the challenges of modern learning and to keep pace with global scenarios. Students are provided training on SAP, Tally ERP 9, MS Office-2016, and the Internet. The Institute website has been dynamically designed to update the latest development in the institute on a day to day basis.
    `,
    image: [computer_lab_2],
  },
  {
    id: 7,
    title: "Laboratories",
    description: `A scientific temperament is nurtured through fully equipped and suitably designed laboratories for Physics, Chemistry, and Biology. Students are encouraged to use the facilities for enhancing their innovative and scientific skills. It also provides an open environment for students to experiment and do research work. All these labs are provided with lecture/ seminar areas too. The three divisions of the science department ensure that students do real experiments.
    `,
    image: [laboratories],
  },
  {
    id: 8,
    title: "",
    description: `Our science labs are not just a rich storehouse of costly equipment and the latest gadgets on display. They are workshops of practical experiments going far beyond the limits of the prescribed syllabus. The teachers at the physics, chemistry, and biology labs challenge them with exciting projects, giving them a strenuous time clashing with known principles and existing data. This is a place where the divine curiosity of students is at work.
    `,
    image: [laboratories_2],
  },
  {
    id: 9,
    title: "Home Science Laboratory",
    description: `Home Science is a very important subject in gaining knowledge to meet the challenge of day-to-day life with confidence. The school has a well-equipped Home-Science Lab where students are acquainted with hands-on knowledge and skills. The teacher conducts different activities in the lab like cooking, stitching, embroidery, knitting, and home management. The home science students can learn Diet Planning for regular and therapeutic needs, Floor decoration, Tie & Die, Flower Arrangement, Block Printing, Samples of different Stitches, Cooking of different recipes, and Table setting.
    `,
    image: [home_science],
  },
  {
    id: 10,
    title: "",
    description: `The home science teacher demonstrates various recipes on bakery, salads, Indian, Chinese, Mexican, and Italian cuisines. The students learn the art of cooking and hone their culinary skills by using gadgets and equipment. They also make use of these lab facilities in their practice teaching, final skill-in teaching, and craft exam.
    `,
    image: [home_science_2],
  },
  {
    id: 11,
    title: "Art & Craft Room (The Heart of Creativity)",
    description: `The Art Room fosters the creative and intellectual development of the students through quality visual arts programs, facilitated by the arts faculty. The art room drives each child to be curious and original in their work while they study a variety of Art forms such as 2D, 3D, and installations by exploring mediums like acrylic, charcoal, water, dry pastels, oil pastels, pencil coloring, clay, wires, papier mache, etc.
    `,
    image: [arts],
  },
  {
    id: 12,
    title: "",
    description: `The periods in the Art Room offer each child a learning experience that teaches him or her to be a risk-taker, thinker, inquirer, and a good communicator in a safe environment. The Art teachers help the students in drawing, painting, crafts work, best out of waste, and clay modeling. Providing the students with experiences in art, craft and design enables them to reflect critically on their own and others’ work. We aim that they learn to think and act as artists, working creatively and intelligently.
    `,
    image: [arts_2],
  },
  {
    id: 13,
    title: "Library",
    description: `The serene ambiance inspires a love for reading. Information at the click of a fingertip invites young users to delve into the magical world of words and the wealth of research material available. The school has a well-equipped library.  The eclectic collection that export constitutes the Library currently includes over 10,000 books. The Library resources comprise Encyclopedias, Dictionaries on various subjects, general collections, valuable sourcebooks, critical works as well as specific skills-related books on Personality Development, Time Management, and Communication Skills. 

    `,
    image: [library],
  },
  {
    id: 14,
    title: "",
    description: `The Library subscribes to research e-journals, besides subscribing to daily newspapers, magazines, and journals. The Digital library provides access to information not just to widen the horizons of the users' knowledge but also to support a host of their learning activities that range from book learning and newspaper reading to using e-journals and reading e-books.
    `,
    image: [library_2],
  },
  {
    id: 15,
    title: "Cultural Center (Music & Dance Room)",
    description: `This is the place where young musicians start their journey to become great composers & where the children unwind and learn the aesthetics of movement. Classical Dance (Indian & Western), Modern Dance (Indian & Western), Choreography,  Direction,  Vocal  Music (Indian and Western), Instrumental Music (Indian and Western) are all interwoven into the fabric of our educational experience.`,
    image: [cultural],
  },
  {
    id: 16,
    title: "",
    description: `Dance is an ideal means of communication to express what is too deep, too fine for words. Hermann Gmeiner School’s students learn to choreograph and are conversant with various Indian and Western dance forms like Indian Classical, Jazz, Hip Hop, Salsa and these are some of the most eagerly awaited moments for self-expression and the students can be seen enjoying to the hilt.`,
    image: [cultural_2],
  },
  {
    id: 17,
    title: "",
    description: `Lessons in Indian and Western music generate rich cross-cultural exposure and sensitize the students to this fine art. The Department of Music and Dance is committed to excellence, innovation, learning in action, and entrepreneurship. Our mission is to bridge dance practice and scholarship and to foster interdisciplinary inquiry.`,
    image: [cultural_3],
  },
  {
    id: 18,
    title: "",
    description: `As a trained faculty, we bring a challenging range of perspectives to our teaching and research, and continually re-examine our curriculum to prepare both young and mature aspirants for careers in the ever-evolving field of Performing Arts.`,
    image: [cultural_4],
  },
];

export const results_data = [
  {
    title: "Toppers: X",
    description_image: [result_10, result_10_1, result_10_2, result_10_3],
    custom_class: "custom-img",
  },
  {
    title: "Toppers: XII",
    description_image: [result_12_1, result_12_2, result_12_3],
    custom_class: "custom-img",
  },
];

export const about_data = [
  {
    title: "About Us",
    description: `Hermann Gmeiner School, Faridabad was founded in 1986 by Shri J.N Kaul who was the then President of SOS Children’s Village of India.
      It is one of the oldest schools in Faridabad with a bunch of exceptionally talented students and alumni. It is a co-educational school with classes from Nursery to XII (Medical, Non-Medical, Commerce, and Humanities).`,
  },
  {
    title: "",
    description:
      "The school has world class infrastructure that makes it comfortable for the students to learn and grow. The faculty leaves no stone unturned to look after the development of the students. The alumini’s experiences speak for the successful careers the students established here.",
  },
  {
    title: "Our Values",
    description:
      "Hermann Gmeiner was a man of principles and ethics. His love and compassion for children is what led him to do the social deed of education and establish SOS Children’s Village Association in 1949. Today, these institutions are active in almost 135 countries, spreading its love and knowledge.",
  },
  {
    title: "",
    description:
      "Our value and virtue is what we strive for and work our very best for all our students to learn and adapt the goodness all over. We teach our children how to excel in not only academics but every field out there and how to make a name and position for self. We teach them how to handle life’s hardships along with book and physical knowledge.",
  },
];
export const nep_data = [
  {
    id: 1,
    icon: <FaSchool />,
    description:
      "Hermann Gmeiner School pedagogy strives to make the schooling process more insightful. It has expanded the horizon of learning and made it more interesting, and motivating. The aim is to focus on the all-round development of the students rather than focusing only on the theoretical part. This makes them ready to face the outer world.",
    extra_description: ["NEP"],
  },
  {
    id: 2,
    title: "Our Curriculum aims to",
    description: "",
    features: [
      "Encourage students to think critically and solve problems through project-based learning",
      "Seamlessly integrate art into the curriculum to enhance the appreciation for aesthetic visual and performing arts.",
      "Foster holistic development through sports, i.e. Health and Physical Education.",
      "Offer activities for curricular and co-curricular enrichment via clubs and work education.",
      " Engage students in addressing real-world problems and issues important to humanity to lead change.",
      " Provide differentiated teaching-learning activities to personalize learning.",
    ],
  },
  {
    id: 3,
    title: "Key Features of the Curriculum",
    description: "",
    features: [
      "Individualized attention – low student-teacher ratio",
      "Stimulating learning environment",
      "Emphasis on all-round development and co-curricular activities",
      "Integration of ICT and Innovation in the Curriculum",
      "Parental Engagement Programmes",
      " Eco-Literacy Curriculum",
      "360-degree assessment approach",
      "Skill-based subjects Imbibing values & social skills",
    ],
  },
];
export const life_curriculum_data = [
  {
    id: 1,
    description: `Adapting the Life Skill Curriculum in the daily routine of every child at the primary stage is a very crucial part of our education. The basic hygiene importance like handwashing and daily bathing, and the value of chivalry and gratitude towards one another must be taught at their impressionable age and fortified over the years. The three components of the Life Skill Curriculum; the individual life-skill, the social skill, and the effective decision making skills are some of the eminent topics of our syllabus. To not only make our students academic geniuses, but to also refine their social and personal skills is what we aim for.`,
    // description_image: [life_skill_1],
  },
  {
    id: 2,
    title: "",
    description: `We believe in testing and creating challenges for our students, so they can break their barriers and overcome their judgemental and stereotypical fears. To educate all of them about daily chores, irrespective of the gender, makes the batchmates comfortable and more open to these topics. The ten core Life Skills adopted by our education system are - 1. Self-awareness, 2. Empathy, 3. Critical Thinking, 4. Creative Thinking, 5. Decision Making, 6. Problem Solving, 7. Effective Communication, 8. Interpersonal relationship, 9. Coping with stress, 10. Coping with Emotion.
    `,
    description_image: [life_skill_2],
  },
];
export const examination_data = [
  {
    id: 1,
    title: "Assesment",
    description: `At specific periods of time, we conduct secure and stable examinations for the students of all grades to not only test their capabilities but also to help them gain self-confidence. The children are marked on the basis of their academic and co-curricular results along with their year-long behaviour and activities.`,
  },
];
export const skill_vocational_data = [
  {
    description: [
      `Vocational subjects include more hands-on training and less of the traditional academic learning. We at Hermann Gmeiner School focus on integrating art with the curriculum for all the students to appreciate the aesthetics and visual performing arts. Practical or first-hand knowledge about the market or trade is a very essential part of the vocational training that we provide to the learners.`,
      `We always prioritize our students and for that we offer differentiated teaching-learning activities as well as personalized learning. All the varied Skill and Vocational subjects are included and taught at our institution. A team of dignified and intellectual teachers is always on the stand to help the students master the subjects.`,
      `Emulating the skill-based model of education as per NEP 2020 and CBSE guidance, we have also introduced the skill subjects at the secondary level. Choosing any one Skill subject at this level can help the child to pursue what truly interests or pleases him or her. This liberty promotes a sense of self-esteem in accepting one’s talents and strengths.`,
    ],
  },
  {
    title: "Classes VI-VIII",
    features: ["Mass Media", "Financial Literacy", "Handicraft"],
  },
  {
    title: "Classes  IX-X",
    features: [
      "Information Technology",
      "Banking & Insurance",
      "Introduction to Tourism",
    ],
  },
  {
    title: "Classes XI-XII",
    features: ["Web Application", "Financial Literacy", "Mass Media "],
  },
];
export const co_curricular_data = [];
export const house_clubs_data = [
  {
    id: 2,
    title: "Sports Club",
    description: `The sports club is an organization of students that aims to develop an interest in sports and physical activities among children. It introduces different kinds of sports in school and instills a healthy lifestyle among students. The sports club motivates the students to get out of their comfort zone and challenges them to be better. The club aims to increase the participation of students by conducting fun and engaging activities.`,
    image: [sports_club],
    icon: [sports_club_icon],
  },

  {
    id: 3,
    title: "Eco Club",
    description: `Eco club is an association of students that encourages and motivates students to take up meaningful environmental activities. It encourages the students to keep their surroundings clean and also influences others to do the same. It aims to teach the concept of “sustainable learning” which is often talked about, but not implemented.`,
    image: [environment_club],
    icon: [environment_club_icon],
  },
  {
    id: 4,
    title: "Health Club",
    description: `
    Health Club
     is an association of students that look after the health of the students in the school. It does not look only after the physical health, but also after the mental, social and emotional health of the children. The health club conducts activities like yoga and fitness sessions so that the students inculcate a healthy lifestyle.`,
    image: [health_1],
    icon: [health_club_icon],
  },

  {
    id: 5,
    title: "Social Impact Club",
    description: `It is a community of students that aims to talk about the importance of community building. It motivates students to take part in social activities and be active members of society. It undertakes activities intending to build meaningful connections and relationships. It teaches students teamwork and compassion.`,
    image: [social_impact],
    icon: [social_impact_club_icon],
  },

  {
    id: 6,
    title: "Communication Club",
    description: `Communication club is a group of students that strives to improve their communication and presentation skills of the students. It helps to improve the language skills of the students and make them proficient in speaking. This is done by a series of fun and interactive games through which students learn about expressions, confidence and elocution.`,
    image: [budding_2],
    icon: [communicationClub],
  },
  {
    id: 7,
    title: "House System",
    description:
      "Hermann Gmeiner School Jammu's House system is designed to foster teamwork and a sense of belonging to the school. This also allows the school's senior students to provide care and guidance to their younger schoolmates. The House structure provides excellent leadership and peer group possibilities for students. In addition, the House structure at Hermann Gmeiner School Jammu fosters a healthy competitive spirit among students from various Houses. It's a fantastic way to bring out students' hidden talents.",
    image: [house_system],
  },
  // {
  //   id: 13,
  //   title: "School Disaster Management Committee (SDMC)",
  //   description: `The school disaster management committee makes students aware of the various hazards and disasters. It conducts online and offline sessions where the students are shown the videos like fire safety, earthquake readiness, etc. It conducts mock drills in school so that the children get hands-on experience of dealing with such situations.
  //   `,
  //   image: [sdmc],
  // },
  // {
  //   id: 14,
  //   title: "",
  //   description: ` It also includes making students empathetic towards their classmates, making them understand the varying needs of their fellow students and people. It imparts understanding on concepts like the buddy system, children with special needs (orthopedic, visual, or learning). With this, children become compassionate and helpful towards their classmates and people in need.`,
  //   image: [sdmc_2],
  // },
];

export const houses_school = [
  {
    id: 1,
    title: "Chenab House",
    description: `The word Chenab is a formulation of the words “Chen'' which means moon and “ab which means river. It is called the moon river. Chenab house symbolizes optimism, hope, and the rhythm of time. The Chenab house works under the supervision and guidance of Mr. Meenesh Kumar and Ms. Rekha Koul.They create an environment of disciple and learning.`,
    color: "#FFF616",
    houseImg: [yellow],
  },
  {
    id: 18,
    title: "Jhelum House",
    description: `The name of the city is derived from the words Jal (pure water) and Ham (snow), as the water that flows through the river originates in the Himalayas. The river symbolizes faith, friendship and conviction. The Jhelum House is headed by Mrs. Anju Jalali and Ms. Anu Chopra. They make sure that the house stands up to its established principles. `,
    color: "#24EA03F5",
    houseImg: [green],
  },
  {
    id: 19,
    title: "Ravi House",
    description: `Ravi river is one of the five tributaries of River Indus. Ravi river represents wisdom. sincerity and intelligence. The house works under the leadership and guidance of Ms. Arvind Wali and Mr. SangeetaDhar. They have established guidelines for the house and have developed an environment where every student challenges themself to be better versions of themselves.`,
    color: "#0440B6D6",
    houseImg: [blue],
  },
  {
    id: 20,
    title: "Tawi House",
    description: `Tawi is a river that flows through the city of Jammu. The Tawi is a major left-bank tributary of the river Chenab. Tawi river is considered sacred and holy, as is the case with most rivers in India. It symbolizes enlightenment, imagination, creativity, and intuition. This house blooms under the leadership of Ms. Sandeep Thapar and Mr. Rajinder Kumar Koul.`,
    color: "#FF1212",
    houseImg: [red],
  },
];
export const student_council_data = [
  {
    id: 1,
    description: `The school comprises the student council headed by the Head Girl and Head Boy along with the Vice Head Girl and Vice Head Boy. The houses are represented by the House Captains and Vice Captains. The council also has representation for the cultural, sports, and discipline committees represented by students heading these particular areas. 
    `,
    description_image: [student_council_1],
  },
  {
    id: 2,
    title: "",
    description: `The students who are elected as heads of all the various committees and councils are guided and trained for undertaking their responsibility with sincerity and passion by the teachers and house in charge. The responsibility of discipline of other students and maintenance of peace and dignity in school also falls under the duties of these elected leaders. The Student Council not only looks after the school's regulations but also helps the students in various activities and campaigns.`,
    description_image: [student_council_2],
  },
];
export const disclosure_data_general = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "NAME OF THE SCHOOL",
    description: "Hermann Gmeiner School",
  },
  {
    id: 3,
    title: "2",
    name: "AFFILIATION NO (IF APPLICABLE)",
    description: "730030",
  },
  {
    id: 4,
    title: "3",
    name: "SCHOOL CODE (IF APPLICABLE)",
    description: "23527",
  },
  {
    id: 5,
    title: "4",
    name: "COMPLETE ADDRESS WITH PIN CODE",
    description: "Gole Gujral, Talab Tillo, Jammu, Jammu and Kashmir-180002",
  },
  {
    id: 6,
    title: "5",
    name: "PRINCIPAL NAME",
    description: " Ms. Shakun Choudhary",
  },
  {
    id: 7,
    title: "6",
    name: "PRINCIPAL QUALIFICATION",
    description: "M.Com , B.Ed",
  },
  {
    id: 8,
    title: "7",
    name: "SCHOOL EMAIL ID",
    description: "principal.jammu@Hermann-gmeiner-edu.org",
  },
  {
    id: 9,
    title: "8",
    name: "CONTACT DETAILS (LANDLINE/MOBILE)",
    description: `(0191)- 2503326/2501651, 9596379566`,
  },
];
export const disclosure_data_documents = [
  {
    id: 1,
    title: "S. No",
    name: "DOCUMENTS/INFORMATION",
    description: "LINKS OF UPLOADED DOCUMENTS",
  },
  {
    id: 2,
    title: "1",
    name: "COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY",
    customcomponent: require("../src/pdf/extension.pdf"),
  },
  {
    id: 3,
    title: "2",
    name: "COPIES OF SOCIETIES/TRUST/COMPANY REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE",
    description: "",
    customcomponent: require("../src/pdf/REGD.PDF"),
  },
  {
    id: 4,
    title: "3",
    name: "COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT",
    description: "",
    customcomponent: require("../src/pdf/NOC.pdf"),
  },
  {
    id: 5,
    title: "4",
    name: "COPIES OF RECOGNITION LETTER UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE",
    customcomponent: require("../src/pdf/Recog.pdf"),
  },
  {
    id: 6,
    title: "5",
    name: "COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE",
    description: "",
    customcomponent: require("../src/pdf/structuralstability.pdf"),
  },
  {
    id: 7,
    title: "6",
    name: "COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY",
    description: "",
    customcomponent: require("../src/pdf/firesafety.pdf"),
  },
  {
    id: 8,
    title: "7",
    name: "COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/ UPGRADATION/ EXTENSION OF AFFILIATION OR SELF CERTIFICATION BY SCHOOL",
    customcomponent: require("../src/pdf/get2.pdf"),
  },
  {
    id: 9,
    title: "8",
    name: "COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES",
    description: "",
    customcomponent: require("../src/pdf/wateranalysis.pdf"),
  },
];
export const disclosure_data_results = [
  {
    id: 1,
    title: "S. No",
    name: "DOCUMENTS/INFORMATION",
    description: "LINKS OF UPLOADED DOCUMENTS",
  },
  {
    id: 2,
    title: "1",
    name: "FEE STRUCTURE OF THE SCHOOL",
    description: "",
    customcomponent: require("../src/pdf/part-1.pdf"),
  },
  {
    id: 3,
    title: "2",
    name: "ANNUAL ACADEMIC CALENDAR",
    description: "",
    customcomponent: require("../src/pdf/part-3.pdf"),
  },
  {
    id: 4,
    title: "3",
    name: "LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)",
    description: "",
    customcomponent: require("../src/pdf/smcmembers.pdf"),
  },
  {
    id: 5,
    title: "4",
    name: "LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS",
    description: "",
    customcomponent: require("../src/pdf/ptamembers.pdf"),
  },
  // {
  //   id: 6,
  //   title: "5",
  //   name: "LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER APPLICABILITY",
  //   description: "",
  //   customcomponent: require("../src/pdf/threeyearsresult.pdf"),
  // },

  {
    id: 6,
    title: "5",
    name: "HOLIDAYS",
    description: "",
    customcomponent: require("../src/excel/Holiday List-Students-2024 (1).docx"),
  },

  {
    id: 7,
    title: "6",
    name: "RESULT",
    description: "",
    customcomponent: require("../src/excel/RESULT_HERMANN GMEINER SCHOOL, JAMMU.xlsx"),
  },
];
export const disclosure_data_staff = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "PRINCIPAL",
    description: "Mr. Subrata Kundu",
  },
  {
    id: 3,
    title: "2",
    name: "TOTAL NUMBER OF TEACHERS",
    name1: "PGT",
    name2: "TGT",
    name3: "PRT",
    description: "43",
    description1: "09",
    description2: "16",
    description3: "17",
  },
  {
    id: 4,
    title: "3",
    name: "TEACHERS SECTION RATIO",
    description: "1:15",
  },
  {
    id: 5,
    title: "4",
    name: "DETAILS OF SPECIAL EDUCATOR",
    description: "Ms. Ragini Charak",
  },
  {
    id: 6,
    title: "5",
    name: "DETAILS OF COUNSELLOR AND WELLNESS TEACHER",
    description: "Ms.Sunita Raina",
  },
];
export const disclosure_data_results_10 = [
  {
    id: 1,
    title: "S. No",
    extra_title: "YEAR",
    name: "NO. OF REGISTERED STUDENTS",
    description: "NO. OF STUDENTS PASSED",
    extra_description: "PASS PERCENTAGE",
  },

  {
    id: 4,
    title: "1",
    extra_title: "2022",
    name: "94",
    description: "94",
    extra_description: "100",
  },
  {
    id: 4,
    title: "2",
    extra_title: "2023",
    name: "92",
    description: "92",
    extra_description: "100",
  },
  {
    id: 4,
    title: "3",
    extra_title: "2024",
    name: "104",
    description: "104",
    extra_description: "100",
  },
];
export const disclosure_data_results_12 = [
  {
    id: 1,
    title: "S. No",
    extra_title: "YEAR",
    name: "NO. OF REGISTERED STUDENTS",
    description: "NO. OF STUDENTS PASSED",
    extra_description: "PASS PERCENTAGE",
  },

  {
    id: 4,
    title: "1",
    extra_title: "2022",
    name: "77",
    description: "71",
    extra_description: "92",
  },
  {
    id: 4,
    title: "2",
    extra_title: "2023",
    name: "62",
    description: "62",
    extra_description: "100",
  },
  {
    id: 4,
    title: "3",
    extra_title: "2024",
    name: "46",
    description: "46",
    extra_description: "100",
  },
];
export const disclosure_data_school = [
  {
    id: 1,
    title: "S. No",
    name: "INFORMATION",
    description: "DETAILS",
  },
  {
    id: 2,
    title: "1",
    name: "TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)",
    description: "60702",
  },
  {
    id: 3,
    title: "2",
    name: "NO. AND SIZE OF THE CLASS ROOMS (IN SQUARE MTR)",
    description: "36 & 500",
  },
  {
    id: 4,
    title: "3",
    name: "NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS (IN SQUARE MTR)",
    description: "6 & 600",
  },
  {
    id: 5,
    title: "4",
    name: "INTERNET FACILITY",
    description: "Yes",
  },
  {
    id: 6,
    title: "5",
    name: "NO. OF GIRLS TOILETS",
    description: "20",
  },
  {
    id: 7,
    title: "6",
    name: "NO. OF BOYS TOILETS",
    description: "20",
  },
  {
    id: 8,
    title: "7",
    name: "LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL COVERING THE INFRASTRUCTURE OF THE SCHOOL",
    description: "https://www.youtube.com/watch?v=wzlcIv5BESM",
  },
];
export const trip_data = [
  {
    id: 1,
    description: `At Hermann Gmeiner School Jammu, we believe that trips and excursions are a perfect way to expand one’s horizons. This is why we organise regular excursions for students to promote learning through outdoor interactions and experiences. In addition, such trips also instil a sense of appreciation for the wonders of the earth and expose one to a wide variety of cultural and geographical ecosystems.`,
    description_image: [trips1],
  },
  {
    id: 2,
    title: "",
    description: `
    These trips are a relief from the mundane routine that the children have. It not only acts as a rejuvenating break but also provides exposure to the students by visiting different places. It boosts their morale by increasing their confidence and being independent. The children develop a variety of skills like observational skills, team spirit, and time management.`,
    description_image: [trips2],
  },
];
export const ncc_scouts_guides_data = [
  {
    id: 1,
    title: "NCC, Scouts & Guides",
    description: `To inculcate discipline and leadership qualities, a large number of students are being trained under the NCC & Scouts and Guides for making them capable, sensitized to work for the betterment of society and for enhancing their lifestyle and leadership qualities.`,
  },
  {
    id: 2,
    title: "NCC",
    description: `NCC aims at developing character, comradeship, discipline, a secular outlook, the spirit of adventure, and ideals of selfless service amongst young citizens. Further, it aims at creating a pool of organised, trained, and motivated youth with leadership qualities from all walks of life, who will serve the Nation regardless of the career they choose. NCC aims to provide a suitable environment to motivate the youth to take up a career in the Armed Forces.`,
    description_image: [ncc_1],
  },
  {
    id: 3,
    title: "Scouts and Guides",
    description: `Scouts is a voluntarily educational movement for people, mostly teenagers, which is open to all without discrimination of gender, caste, race, or any religion. The whole idea behind Scouts is to contribute to the development of youngsters in gaining their full physical, mental, emotional, social, and intellectual ability as individual human beings, as responsible Indians, and as members of their local, national, and international communities.
    A guide is someone who leads humans towards their goals. A guide helps the students to grow mentally, physically, socially, and emotionally. They help them to make informed decisions and bring in the quality of discipline in them. The children are taught the rules and regulations which makes them more confident and sincere.`,
    description_image: [ncc_2],
  },
  // {
  //   id: 4,
  //   title: "School band",
  //   description: `Music has always proven to be one of the best therapies and to train the students in all aspects, our school has a special band. Training and understanding of various musical instruments, importance of teamwork and knowledge of musical notes are provided to the students. The school band often performs in different events and functions of the schools for which the equipment is both provided as well as allowed to bring on their own.
  //   `,
  //   description_image: [school_band_1],
  // },
];
export const transportation_data = [
  {
    title: "",
    description: `Scouts is a voluntarily educational movement for people, mostly teenagers, which is open to all without discrimination of gender, caste, race, or any religion. The whole idea behind Scouts is to contribute to the development of youngsters in gaining their full physical, mental, emotional, social, and intellectual ability as individual human beings, as responsible Indians, and as members of their local, national, and international communities.
    A guide is someone who leads humans towards their goals. A guide helps the students to grow mentally, physically, socially, and emotionally. They help them to make informed decisions and bring in the quality of discipline in them. The children are taught the rules and regulations which makes them more confident and sincere.`,
    description_image: [bus_1],
    custom_class: "custom-img",
  },
];
export const award_text_data = [
  {
    title: "",
    description: [
      "Hermann Gmeiner School proudly highlights its achievements and presents the best of its students, whether they are excelling academically, athletically or in service to the community. We hope to inspire more students to excel, their achievements ultimately make the school become stronger.",
      "By highlighting student achievements, we aim to eliminate self-doubt and build confidence in our students that hard work is important and is making an impact. In turn, this confidence can carry over to other facets of their young lives, including making smart choices when faced with several challenges as they grow up.",
    ],
  },

  {
    features: [
      "Global School Award for Innovation in Pedagogical Practices in 2020",
      "Best Teaching Excellence Award by International School Award held at Dubai in 2018",
      "Indian School Award in 2018",
      "National School Award 2018",
      "Global Sustainable School Award 2017 as a recognition of its efforts towards community service and environment",
    ],
  },
];
// the long list of awards such as
//stand testimony to the fact that the school never rests on its laurels.

export const awards_data = [
  {
    title: "",
    image: award_8,
  },
  {
    title: "",
    image: award_10,
  },
  {
    title: "",
    image: award_9,
  },
  {
    title: "",
    image: award_1,
  },
  {
    title: "",
    image: award_2,
  },
  {
    title: "",
    image: award_3,
  },
  {
    title: "",
    image: award_4,
  },
  {
    title: "",
    image: award_5,
  },
  {
    title: "",
    image: award_6,
  },
  {
    title: "",
    image: award_7,
  },
];
export const news_text_data = [
  {
    description:
      "Hermann Gmeiner School  has been incessantly creating fervor in society and has been garnering Media coverage, getting its message across to a wider audience and establishing itself as a credible school and its activities as notable.Reputed newspapers have on several ocaasions, published the events and happenings at Hermann Gmeiner School along with the laurels and accolades received. We humbly acknowledge this recognition and strive to be a responsible school and be dedicated towards the community welfare.",
  },
];
export const news_data = [
  {
    title: "",
    image: media1,
  },
  {
    title: "",
    image: media2,
  },
  {
    title: "",
    image: media3,
  },
  {
    title: "",
    image: media4,
  },
  {
    title: "",
    image: media5,
  },
  {
    title: "",
    image: media6,
  },
  {
    title: "",
    image: media7,
  },
  {
    title: "",
    image: media8,
  },
  {
    title: "",
    image: media9,
  },
  {
    title: "",
    image: media10,
  },
  {
    title: "",
    image: media11,
  },
  {
    title: "",
    image: media12,
  },
  {
    title: "",
    image: media13,
  },
  {
    title: "",
    image: media14,
  },
  {
    title: "",
    image: media15,
  },
  {
    title: "",
    image: media16,
  },
  {
    title: "",
    image: media17,
  },
  {
    title: "",
    image: media18,
  },
  {
    title: "",
    image: media19,
  },
  {
    title: "",
    image: media20,
  },
  {
    title: "",
    image: media21,
  },
  {
    title: "",
    image: media22,
  },
  {
    title: "",
    image: media23,
  },
  {
    title: "",
    image: media24,
  },
  {
    title: "",
    image: media25,
  },
  {
    title: "",
    image: media26,
  },
  {
    title: "",
    image: media27,
  },
  {
    title: "",
    image: media28,
  },
  {
    title: "",
    image: media29,
  },
  {
    title: "",
    image: media30,
  },
  {
    title: "",
    image: media31,
  },
  {
    title: "",
    image: media32,
  },
  {
    title: "",
    image: media33,
  },
  {
    title: "",
    image: media34,
  },
  {
    title: "",
    image: media35,
  },
  {
    title: "",
    image: media36,
  },
  {
    title: "",
    image: media37,
  },
  {
    title: "",
    image: media38,
  },
  {
    title: "",
    image: media39,
  },
  {
    title: "",
    image: media40,
  },
  {
    title: "",
    image: media41,
  },
  {
    title: "",
    image: media42,
  },
];
export const alumni_text_data = [
  {
    id: 1,
    title: "Rohan Koul",
    occupation: "Data Scientist - SAP Labs India",
    description: `After passing the Class XII exams from Hermann Gmeiner School, I worked hard for my entrance examinations and got a seat at the prestigious MIT Pune. Hard work and dedication were instrumental in securing a place at MIT Pune. In college, my focus was on getting practical knowledge and preparing myself for the industry. I spent a lot of time developing different types of software and participating in various national and international competitions. Finally, I got placed at SAP Labs India and currently, I am working as a Data Scientist. My motivation is always to keep working hard, keep learning, keep an open mind, keep growing, and stay sincere in my work.
    `,
    image: rohan,
  },
  // {
  //   id: 2,
  //   title: "Karandev Singh",
  //   occupation: "Business Analyst - American Express",
  //   description: `I attended Hermann Gmeiner School, Jammu from class 7 to 12, and without a doubt, these were the best years of my student life. The school provided me with an encouraging and motivating environment, building a platform for me to excel not only in studies but in other extracurricular activities as well. The focus was always on holistic development and this set me up strongly to take on different challenges in life. I will forever be thankful to my teachers who guided me in the best way possible and had a major role in shaping my life. I will always cherish the time that I spent at Hermann Gmeiner School and wish the best to all fellow HGians in their lives.`,
  //   image: karandev,
  // },
  {
    id: 2,
    title: "Anjan Raina",
    occupation: "Microsoft Engineer",
    description: `I attended Hermann Gmeiner School, Jammu from class 7 to 12 and without doubt, these were the best years of my student life. The school provided me with an encouraging and motivating environment, building a platform for me to excel not only in studies but other extracurricular activities as well. The focus was always on holistic development and this set me up strongly to take on different challenges in life. I will forever be thankful to my teachers who guided me in the best way possible and had a major role in shaping up my life, setting me up for success. I will always cherish the time that I spent here, and wish the best to all fellow HGans in their lives.`,
    image: anjan,
  },
  {
    id: 3,
    title: " Dr. Prakriti Sharma",
    occupation: "Dermatologist - GMC Jammu",
    description: `I joined Hermann Gmeiner School in 5th standard, I used to enjoy the regular classes, lunch breaks, and playtime just like any other student. To be honest, my first inclination in terms of school activities was towards music and fine arts. For the same reason, I participated in various music, dance and painting competitions. My teachers helped me recognize my potential. After 10th standard, I took PCM along with painting as an additional subject. In 2011, I appeared for JKCET and on the first attempt, I got selected for MBBS in SKIMS Medical College, Srinagar and for this accomplishment, I would always be grateful to my school that has made me the person I am today and for being the wings for my flight. It has taught me to be a good human, a good learner & a good listener. I have been in the medical profession for the last 10 yrs and presently I am doing my Post graduation in dermatology at GMC Jammu meanwhile cherishing my roots.`,
    image: prakriti,
  },

  {
    id: 4,
    title: "Abhishek Talashi",
    occupation: "Senior Manager - IDFC Bank Mumbai",
    description: `I was a student of SOS Hermann Gmeiner School, Jammu from 2006 to 2011 completing my higher secondary from there. The school acted as a portal for me to explore my academic and sports interests. The teaching staff was very knowledgeable and helped me in understanding various topics. Hermann Gmeiner School was a building block for my higher education and my current career.`,
    image: abhishek,
  },
];
export const sports_data = [
  {
    id: 1,
    title: "Sports",
    description: `Having an active lifestyle is key to living a healthy and fit life. To ensure that our students are not only just engrossed in studies, to promote a spirit of sportsmanship and camaraderie, we encourage the students to participate in regularly held intra and inter school events within and outside of the Hermann Gmeiner School community. Taekwondo, Football, Badminton, Lawn Tennis, Table Tennis, Cricket, Chess and Yoga`,
  },
];

export const faculty_data = [
  {
    title: "Faculty & Staff",
    description: `Hermann Gmeiner School Jammu has a very experienced, knowledgeable, 
    well-qualified and dedicated Staff, so much so that they are conscious of their 
    responsibility of being the most important block in the foundation of the Nation 
    as well as the Society.`,
    description_image: [faculty_1, faculty_2],

    custom_class: ["faculty-img"],
    extra_description: [
      `Their self-discipline is an example in itself, which always drives them to work as 
    a well-knit team to make all possible endeavour together for the holistic growth 
    of students as well the institution. They are enthusiastic, dedicated, 
    conscientious and highly motivated to adapt to new innovative ideas and well 
    versed with modern education systems.`,
      `The valuable contribution of all the Faculty members is well manifested in the 
    spectacular achievements of our students in the scholastic as well as co-
    scholastic discipline.`,
      `The Teachers grouped under various subject-wise Faculties are put under the 
    guidance of the most experienced Faculty heads. All of them always put their 
    heads together to devise improvised methodology to deliver their best to 
    facilitate the young evolving minds. This significantly contributes in promoting 
    the problem solving skills of students to enable them to grow as useful citizens.`,
    ],
  },
];

export const events_data = [
  {
    title: "Head Education and Child development visit",
    description: `Ms. Debaroti Bose Head Education and Child Development SOS Children's Villages of India visited Hermann Gmeiner School Jammu on September 27, 2021 and May17 to May 20, 2022. During her visit, she interacted with all faculty members, the students who have participated in National Children Science Congress Model/ Project presentation, NCC cadets, young buds who have brought laurels to the school in music and dance competitions. While interacting with Science Faculty she stressed upon experiential learning and to inculcate and develop scientific temperament among children.`,
    description_image: [educationVisit],
  },
  {
    title: "Annual Fest",
    description: `Annual Fest forms an integral part of our school activities.  It is an occasion of celebration, felicitation, feast and festivity when students present not only their wonderful performances but also receive honours for their curricular.
    Hermann Gmeiner School Jammu celebrated Annual Fest in the school’s premises. The programme started with a musical odyssey presented by the school choir and a team of musicians.  
    The chief guest for the event was Sh. Chander Mohan Gupta, Chairman JMC  unfolded the event with the lighting of the ceremonial lamp and  inaugurated the fest. 
    
    The principal, presented the annual school report, following which students who had achieved curricular and co-curricular feats were given prizes.  Award, was given to the student who scored the highest marks in the board exam, while the medals and trophies were given to the student who has been dedicated and sincere in academics and co-curricular activities.  In addition to co-curricular activities, students enjoyed camel ride, science & art exhibition, yoga and games & food stalls.
    `,
    description_image: [annual_1],
  },
  // {
  //   title: 'The theme of the Annual Day was "Technoutsav"',
  //   description: `The Annual Prize Distribution function was a moment of pride and honour for all the winners and achievers of the school. The students were awarded prizes in the Academic as well as Co Curricular areas. The function included Saraswati Vandana, Orchestra by the students and Fusion Dance.`,
  //   description_image: [annual_3],
  // },
  // {
  //   title: "",
  //   description: ` Principal Mr. Subrata Kundu  extended a warm welcome to the dignitaries and presented the Annual Report. He read out the achievements of the school both Academic and Co curricular and wished success to the students who could not make up for the prizes.`,
  //   description_image: [annual_2],
  // },
  // {
  //   title: `Republic Day Celebration`,
  //   description: `On 26th January 2022, Hermann Gmeiner School Faridabad commemorated the 73rd Republic Day on the live and virtual platform with great patriotic fervour, mirth, and zest. The zealous students of Hermann Gmeiner School demonstrated that even though the pandemic has confined them to their homes, it cannot dampen their patriotic spirit and pride for the nation. `,
  //   description_image: [republic_1],
  // },
  // {
  //   title: "",
  //   description: `The programme was graced by the distinguished presence of our revered Principal Mr. Subrata Kundu with our Guest of Honour Mrs. Ankita Lal (Alumnus of Hermann Gmeiner School Faridabad batch 2000), Judicial Officer in New Delhi, Headmistress (Senior School), Ms. Vandana Tomar, Headmistress (Primary School), Ms. Manjeet Kaur, respected teachers, parents, and the learners. The programme commenced with the unfurling of our Indian Flag and reflection upon the values of courage, strength, peace, truth, growth and progress that the tricolour teaches us to adopt. As the session unfolded, our Principal, Mr. Subrata Kundu, inspired the students with his motivational words. He hailed the power of youth and urged the learners to assume the role of building up the nation. `,
  //   description_image: [republic_2],
  // },
  // {
  //   title: "",
  //   description:
  //     " The presentation further included speech, dances, song, instrumental presentation, Hindi and English poems and role play and impactful videos of primary and preprimary students. Through the show, students paid tribute to the sacrifices made by the freedom fighters.They emphasised how the Republic Day celebration reaffirms our commitment to unity in diversity. The programme concluded with the encouraging words of the school Headmistresses, Ms. Manjeet Kaur who congratulated the entire team and the participants for this wonderful presentation. Their hearts swelled with pride to see the young children grow into confident and aware individuals - ready to take on the world with the light of hope and positivity.",
  //   description_image: [republic_3],
  // },
  // {
  //   title: `Basant Panchami`,
  //   description: `The festival of Basant Panchami, which marks the end of winter and the onset of spring is celebrated on the fifth day (Panchami) of the Hindu month of Magha. This day is considered to be the birthday of Goddess Saraswati, as she is said to have appeared on earth to dispel the gloom of all living beings, through the gift of speech and music. Our children celebrated this festival with great zeal by wearing yellow clothes, preparing yellow food with the help of their parents, participating in online celebrations & also making colourful kites. We ended this celebration by inculcating the values & keeping in mind our tradition.`,
  //   description_image: [basant_1],
  // },
  // {
  //   title: "Activity 1",
  //   description: `Students of class V are learning through experiencing, during online classes. They are making innovative projects under the guidance of teachers like making Air coolers, and Vacuum cleaner with the help of waste materials available at home. The cooler consumes less electricity as compared to big coolers and is portable which we can carry in vehicles and works without electricity on battery too. This activity is a perfect example of learning by doing.`,
  //   description_image: [basant_2],
  // },
  // {
  //   title: "Activity 2",
  //   description: `The students of 5th Grade made a model of lungs while their course of learning. The process of breathing was learned by them through hands-on experience which made their concept more clear about exhaling and inhaling done by our respiratory system.`,
  // },
  // {
  //   title: `Donation Drive`,
  //   description: `“THE WORLD NEEDS EVERY SUPPORTING HAND WE CAN MUSTER.”
  //   Eco Club of Hermann Gmeiner School Faridabad organized a donation drive initiative for Vanprasth Vridhjan Sewa Sadan, an old age home in sector 28, Faridabad where interactions were expressed their gratitude by conducting a donation of vegetables grown by students in the School Nutrition Garden. SNG promotes students to get connected to a natural world and makes them aware of the true source of their food, teaches them valuable gardening and agriculture concepts and skills that integrate with several subjects maths, science, health & physical education as well as interpersonal and social responsibility.
  //   `,
  //   description_image: [donation_1],
  // },
  // {
  //   title: "",
  //   description: `Mr. Ramesh K. Dhar, Principal along with the team of students and teachers came forward and handed over the fresh vegetables to Mr. Anil Sarin, President of the old age home. Receiving a fairly nice amount of vegetables and face masks for the inmates, he was thrilled. This attempt to help the people may not alleviate all their problems but may decrease their suffering to some extent.These community activities organized by the school are not just a display of our social responsibility but also a forum to instil the values of empathy and compassion among students. These are life skills demonstrated in action.`,
  //   description_image: [donation_2],
  // },
  // {
  //   title: `Paper Bag`,
  //   description: `"There is no exercise better for the heart than reaching down and lifting people." Hermann Gmeiner School, Faridabad has been actively involved in community service and has sought ways to enhance this involvement. It strives to provide a range of social service opportunities that will allow our students to expand their understanding of social issues and consider their role in finding solutions to community concerns. The Social Impact team organised a PAPER BAG-making workshop using an old newspaper for FSP Tilak Nagar beneficiaries. The students of grade IX gave training on paper-bags making and awareness on reducing single-use plastic bags to 10 women of FSP. It helps them to get employment and generate income. At the end of the session, the special guests were gifted with fresh vegetables harvested from the School Nutritional Garden.`,
  //   description_image: [paper],
  // },
  // {
  //   title: `Waste Is Not A Waste `,
  //   description: `Little hands and imaginative minds of Hermann Gmeiner School Faridabad came together in a creative burst of energy during the activity of the “Waste is not a waste” Project.The project aims to bring our learners to understand the importance of preserving the environment and learn more about sustainability. We should not throw away wasted things, we could use them again for different purposes, and we should not cause harm to nature. `,
  //   description_image: [waste],
  // },
  // {
  //   title: "",
  //   description:
  //     "To make children aware of the uses of waste material, this “WASTE IS NOT A WASTE” Project was initiated for students.Students of all divisions prepared very creative and innovative models and art items from different waste materials. They used reusable and recyclable materials like newspapers, tires, shoe boxes, ice cream sticks, and much more. From these items, they created an amazing array of display items and useful everyday objects. The values like preservation, recycling, conservation, reduction, reuse, etc are imbibed into the young heart and minds by demonstrating this noble act of caring for our environment",
  //   description_image: [waste_2],
  // },
  // {
  //   title: "",
  //   description: `The Prime Minister urged everyone to put exams in the right perspective, rather than making it a life-and-death situation punctuated by undue stress and pressure. The Exam Warriors module on NaMo App adds an interactive tech element to the Exam Warriors movement. He communicated the core messages of each mantra and thought-provoking but enjoyable activities that help absorb concepts through practical means.`,
  //   description_image: [pareeksha_2],
  // },
  // {
  //   title: `Heritage Day Celebration`,
  //   description: `A Celebration of World Heritage Day was organised at Hermann Gmeiner School Faridabad to promote awareness among students about the diversity of culture, heritage, monuments and sites.A special session was conducted for the students in which the teacher talked about the true meaning of heritage, its importance and the need for its preservation. A beautiful video was showcased on seven incredible and famous heritage sites of the world. Students of grade IX told stories about Indian heritage sites. Students of grade X made travel brochures on famous monuments and participated wholeheartedly.The objective behind the activity was to enlighten students about the value and significance the world’s heritage holds. Children were familiarised with the rich cultural heritage we have.`,
  //   description_image: [heritage],
  // },
  {
    title: `Farewell 2021-22`,
    description: `School is the second home of the students and the students are the heartbeat of the school. 
    The students, staff and the management of Hermann Gmeiner School, Jammu bid farewell to 
    the outgoing students of class XII on Friday, 30 April 2022. The class XII (2021-22), being the 
    host, greeted the guest class, with wonderful reception. The students dressed beautifully, made 
    a great impression during modeling rounds. Selected students were conferred Miss. and Mr. 
    Hermann Gmeiner School Jammu tags. The Principal wished Good Luck to all the students of class XII (2021-22) for 
    their future and students also delivered valedictory speeches recalling their best spent days in 
    the school and paid gratitude towards their teachers and Principal sir for being constant source 
    of encouragement, guidance and positivity.`,
    description_image: [farewell],
  },
  {
    title: `Mothers's Day Celebration 2022`,
    description: `Mother's Day was celebrated with zeal and enthusiasm in the school activity hall. The hall 
    decorations displayed warmth and compassion of a mother. The love and sacrifices made by a 
    mother were creatively presented through dance performances. Pious motherhood was 
    expressed with songs by the students. The deep words in poems recited by students seized the 
    attention of the audience. All these performances added magic to this special day devoted to 
    mothers.`,
    description_image: [mother],
  },
  {
    title: `Golden Girl`,
    description: `Shriya Labroo of class X has clinched GOLD medal in the discipline of Karate under the age 
    group of U/17 at inter school District Level competition which was organized by District Youth 
    Services & Sports department Jammu on 12/05/2022 at KHEL GAON NAGROTA.`,
    description_image: [golden],
  },
  {
    title: `Council Elections`,
    description: `Leadership and Learning both are indispensable to each other” At Hermann Gmeiner School Jammu, Student 
    Council Election of categories I, II and III were held on (28.04.2022) with ballot paper voting 
    system to develop and provide leadership opportunities, where every child voted for their 
    favorite candidate.`,
    description_image: [election],
  },
  {
    title: `International Dance Day`,
    description: `Hermann Gmeiner School, Jammu celebrated ‘International Dance Day’ on 28th April,
    2022 with special dance performances staged in a special assembly. Students from primary to 
    secondary classes showcased their talent through various dance performances of Indian culture
    with great zeal and fervor.`,
    description_image: [danceday],
  },
  {
    title: `Pareeksha Pe Charcha `,
    description: `Heartiest Thanks to Honorable Prime Minister Shri Narender Modi ji for the motivational 
    interaction session with the students of 9-12th classes. Need of the hour and such a 
    knowledgeable session for students, teachers and parents.`,
    description_image: [charcha],
  },
  {
    title: `Earth Day Celebration`,
    description: `
    As the demand of time and situation is to protect the environmental beauty and purity of the 
    surroundings, on 22 April, 2022 different activities were organized in classes Nursery, LKG, 
    UKG as a first step towards Earth Day Celebration. As the theme suggests "Clean School, 
    Green School" children enjoyed activities like planting trees and watering the plants. Children 
    also paid a token of appreciation to their teachers by gifting them different saplings. `,
    description_image: [environment],
  },
  {
    title: `Social Impact Programme`,
    description: `
    First Session of Social Impact Literacy programme was conducted at Trilokpur Community 
    center with FS program SOS children village. They were motivated to make use of the magical 
    words -Welcome, please, sorry, thank you, Excuse me. Stress was given upon the usage of 
    these words in their day-to-day life.Teachers and students performed different yoga asanas 
    (Padmasana, Vajrasana, butterfly, paschimottanasana) . Benefits of these asanas were 
    explained to them. A group of members and community women from Khairyan and Trilokpur 
    participated in the program.`,
    description_image: [social_impact],
  },
  {
    title: `Sensitization Programme on Gender Equality`,
    description: `
    The Programme was organized by a team of MIER College of education in Hermann Gmeiner School Jammu, in 
which students of classes 9th and 10th participated. Gender inequality was addressed as the 
social phenomenon in which man and woman are not treated equally. The treatment arises from
distinctions regarding biology, psychology or cultural norms prevalent in society; various queries
put by the students were handled properly. The Principal asked students to make an action plan
so that it can be delivered effectively in classes to other students`,
    description_image: [senitization],
  },
  {
    title: `Ram Navmi`,
    description: `
    Hermann Gmeiner School Jammu celebrated Ram Navmi in school premises on 8 April 2022. A panorama of various
activities was performed by the students. A melodious bhajan and mantras were chanted by 
students defining the divine greatness and powers of Maa Durga and Lord Rama.
Program ignited the young minds and connected them with religion, culture and devotion.`,
    description_image: [ram],
  },
  {
    title: `Visit by Secretary General, Mr. Sumanta Kar`,
    description: `
    Secretary General, Mr. Sumanta Kar visited Hermann Gmeiner School Jammu on 16.03.2002. Principal, 
    Headmaster, HODS of all subjects were present during his visit. He visited classrooms, library, 
science laboratories and school campus. In the bio lab he was introduced to the various projects
presented at state, national and international level. During his interaction with staff, he stressed 
on 3 P's (Purpose, People and Process) for the development of institution.`,
    description_image: [secretaryVisit],
  },
  {
    title: `Covid Vaccination Drive`,
    description: `To contain the swell of Corona Variants by protecting students from this lethal virus,
  a vaccination drive was organized at Hermann Gmeiner School for the students of 
  the age bracket 12 years to 15 years (Classes VI to X) at its campus. This drive 
  followed all the norms laid by WHO, the Ministry of Health, Government of India 
  pertaining to Covid 19. The initiative of the school ensured safe vaccination for the 
  students and allowed children in the vicinity to get benefitted. `,
    description_image: [covid],
  },
  {
    title: `Parent Orientation Program`,
    description: `The foundation for good parent teacher relationship is frequent and open communication, mutual respect and a clear understanding of what is best for an individual child. In pursuit of the same, Hermann Gmeiner School Jammu organised an Orientation Programme for the newly admitted students and parents to familiarise them with the latest curriculum and assessment pattern for the current academic session 2022-23 on Tuesday, 31 May, 2022 in School Activity Hall.`,
    description_image: [parent],
  },
  // {
  //   title: "",
  //   description: `Through a well-organized cultural evening comprising Dances, Songs, Speeches, and Poetry, an atmosphere of love and affection was created. Class XI students presented beautiful attractive gifts to their seniors. The presence of the esteemed Principal of the school, Mr. Ramesh Dhar, and reverent HMs, Ms. Vandana Tomar and Ms. Manjeet Kaur lent an additional charm to the occasion. They showered their blessings upon the students and also motivated the students to develop a tendency of hard work, perseverance, and dedication towards work.`,
  //   description_image: [farewell_2],
  // },
  // {
  //   title: "Mock Fire Evacuation Drill",
  //   description: `Hermann Gmeiner School Faridabad believes it's the responsibility of the school to ensure the safety of its students and staff, and prepare them for any unforeseen emergency. With the kind help of Mr. Ashok (Fire Safety Expert), a mock fire and evacuation drill was conducted at Hermann Gmeiner School today on 26th April 2022. Following an alarm, teachers and other school staff swung into action and performed the duties allotted to them. Students were educated about the safety and precautionary measures to be taken at the time of the fire and other natural disasters. Students were also given tips on fire safety. A demonstration of using fire safety equipment was also conducted by the experts.`,
  //   description_image: [fire],
  // },
  // {
  //   title: "Kids Fashion Fiesta",
  //   description: `Hermann Gmeiner School Faridabad organized Kids Fashion Fiesta for the early years where distinct topics were given to the little ones according to their age.
  //   It was a successful event full of creativity and involvement of the parents too. Kids enjoyed being dressed up and everyone was happy for being part of the program.
  //   The program was graced by our Respected Head of Education and Child Development Ms. Debaroti Bose and the students were excited by her presence. The event was concluded by our Respected Headmistress Ms. Manjeet Kaur on a happy and positive note.`,
  //   description_image: [fashion],
  // },
  // {
  //   title: `Book Donation Drive`,
  //   description: `We Rise by Lifting Others!
  //   Compassion and community welfare are the essences of any good human being; hence an initiative was taken by our Social Impact Club to inculcate these virtues amongst our learners.
  //  The school established a Book Bank facility for the underprivileged children of Gurukul of SOS FSP, Anangpur. Students of Grade IX initiated the project by sorting and packing the numerous books received through the Book Donation Drive organized in the school. They were donated to the children of Baal Panchayat, wherein children do not have the means to buy them. Our children also guided them on how to run the Book Bank. The effort resulted in a fruitful outcome, as children passed the gift of education in their own selfless way.`,
  //   description_image: [book],
  // },
  // {
  //   title: `Orientation Day Programme`,
  //   description: `“A parent is a teacher at home and a teacher is a parent in school and the child is the center of our universe.” An Orientation Programme was organised for the parents of new admission at Hermann Gmeiner School, Faridabad on 21st May 2022. The aim was to familiarise the parents, “as partners in progress” with the curriculum, rules, and regulations of the school, teaching methodologies, and the co-scholastic activities. The programme commenced with a warm welcome to the parents followed by the ceremonial lighting of the lamp. The School Principal, Mr. Ramesh Dhar, addressed the gathering. In his speech, he laid stress on effective communication between the teachers & the parents and also sought their support and suggestions so that the children could be provided the best for their overall development. He also gave guidelines about effective parenting. `,
  //   description_image: [orientation_1],
  // },
  // {
  //   title: "",
  //   description: `The parents were briefed about the curriculum, and teaching methodologies along with new initiatives of the school by the headmistress senior wing, Ms. Vandana Tomar. Later, Ms. Anuradha Rai Shukla, Examination Incharge presented information about the examination and promotion policies of the school. Not to forget the co-scholastic activities, briefed by Ms. Nidhi Sharma, Activity Coordinator, which assured them that the curriculum involves self-directed, hands-on learning activities which not only give vent to their creative talents and energies but also help in the all-round development of the child. `,
  //   description_image: [orientation_2],
  // },
  // {
  //   title: "",
  //   description: `She also explained about the school app. It was followed by a session taken by HM Junior Wing, Ms. Manjeet Kaur, who guided the parents about various principles, curriculum, and activities involved in the teaching in primary and pre-primary classes. The parents witnessed and enjoyed the dance performance by Primary children and a mashup by middle wing girls. The orientation program ended with an interactive session with the parents and a vote of thanks by HM Maam.`,
  //   description_image: [orientation_3],
  // },
  // {
  //   title: "",
  //   description: `The orientation program proved to be a sincere effort made by the school in educating the parents to look after and properly guide their children. It also helped in evolving a common teaching strategy and helped the parents in identifying suitable follow-up methods to reinforce the learning process of their children.Overall it was a fruitful meeting and the parents were in high spirits after attending the orientation programme.`,
  //   description_image: [orientation_4],
  // },
  // {
  //   title: `First Aid Kit Making Activity`,
  //   description: `From a minor ailment to a more serious injury, a "First Aid Kit" can help reduce the risk of infection. Keeping this in mind, our SDMC First Aid Team organised a First Aid Kit Making Activity for the young students of Grade III to VIII on 21st May 2022. Those children were allowed to prepare a First Aid Kit in the class. The kit included cotton swabs, an antiseptic, band-aids, pain relief ointment, etc. The children learned how these medical items can be used to treat minor cuts, burns, and scrapes, and that a first aid kit should always be kept clean and organised. This activity helped the children to learn basic first aid skills and how to make the first aid box that can enable them to care for themselves in any kind of emergency.`,
  //   description_image: [first_aid],
  // },
];
