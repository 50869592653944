import React from "react";
import { Row, Col } from "react-bootstrap";
import PCM from "./PCM";
import PCB from "./PCB";
import './SubjectCards.css'
import Commerce from "./Commerce";
import Humanities from "./Humanities";

const SubjectCards = () => {
  return (
    <div className="subject_cards">
      <Col>
        <Row>
          <Col className="columnn">
            <PCM />
          </Col>
          <Col className="columnn">
            <PCB />
          </Col>
        </Row>
        <Row className="commerce_card">
          <Col className="columnn">
            <Commerce />
          </Col>
          {/* <Col>
            <Humanities />
          </Col> */}
        </Row>
      </Col>
    </div>
  );
};

export default SubjectCards;
