import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import arrow from "../../image/Rectangle 66.svg";
import mainIcon from "../../image/mission_icon.svg";
import dot from "../../image/4dot.png";

import "./Content.css";

function Vision(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div style={{ marginBottom: "-6rem" }}>
        <div
          style={{
            width: "204.11px",
            height: "204.64px",
            marginLeft: "74%",
            marginTop: "-7rem",
            background: "#F919848A",
            borderRadius: "0 0 100px 100px",
          }}
        ></div>
        <div
          style={{
            width: "204.11px",
            height: "204.64px",
            marginLeft: "83.5%",
            marginTop: "-11rem",
            background: "#1FDC007D",
            borderRadius: "0 0 100px 100px",
          }}
        ></div>
      </div>
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          <div className={`f-2 f-500 mb-3`} style={{ color: "#23E303F5" }}>
            <img
              className="visionimage"
              src={mainIcon}
              style={{ marginRight: "1rem" }}
            />
            Vision & Mission
          </div>
          <div
            style={{
              marginTop: "2rem",
              backgroundColor: "#F919840A",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <img src={arrow} />
            <p
              style={{
                fontSize: "1.5rem",
                marginTop: "-3rem",
                marginLeft: "3.2rem",
              }}
            >
              Vision
            </p>
            <div
              className="d-flex gap-2"
              style={{ marginTop: "1.5rem", marginBottom: "1rem" }}
            >
              <div>
                <div className="color-grey f-500" style={{ margin: "1.5rem" }}>
                  We at Hermann Gmeiner School believe in scholarly and
                  accomplished learning. To provide all our students with
                  advanced and qualified academic education along with the
                  knowledge and experience in sports and co-curricular
                  activities has always been our priority. Blending leisure with
                  technology and creating a holistic experience for the
                  educators to have the finest learning experience has been the
                  precedence of our institution. We tend to upskill and upgrade
                  all the children at the primary age with the will and spirit
                  to be the best and brightest version of themselves. This helps
                  them to grow and develop individually and in the society to
                  become a good and contributing citizen of our country.
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: "2rem",
              backgroundColor: "#1FDC000A",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
          >
            <img src={arrow} />
            <p
              style={{
                fontSize: "1.5rem",
                marginTop: "-3rem",
                marginLeft: "3.2rem",
              }}
            >
              Mission
            </p>
            <div
              className="d-flex gap-2"
              style={{ marginTop: "1.5rem", marginBottom: "1rem" }}
            >
              <div>
                <div className="color-grey f-500" style={{ margin: "1.5rem" }}>
                Our mission at Hermann Gmeiner School is to bring our vision to life by delivering a well-rounded, future-focused education. We are dedicated to fostering academic excellence through a modern curriculum that integrates technology, creativity, and experiential learning. By providing opportunities in sports and co-curricular activities, we aim to develop students' physical, emotional, and social well-being. We commit to cultivating an inclusive and nurturing environment where educators and students collaborate to enhance learning experiences. Through personalized guidance, diverse learning approaches, and character-building initiatives, we empower our students to grow into responsible and compassionate citizens.
 
                </div>
              </div>
            </div>
            <img src={dot} style={{ width: "5rem", marginLeft: "90%" }} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Vision;
