export const FORM = {
  STEP_1: [
    {
      name: "reg_no",
      label: "reg_no",
      placeholder: "Enter Registration Number",
    },
    {
      name: "mobile",
      label: "mobile",
      placeholder: "Enter Mobile Number",
    },
    {
      name: "academic_year",
      label: "academic_year",
      placeholder: "Enter Academic Year",
    },
  ],
};
