import React, { useState } from "react";
import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Landing from "../Landing/Landing";
import Navbar from "../NavBar/Navbar";
import NavbarMenu from "../NavbarMenu/NavbarMenu";
import SideBar from "../SideBar/SideBar";
import b from "../../image/parents/b.png";
import c from "../../image/parents/c.jpg";
import d from "../../image/parents/d.png";
import e from "../../image/parents/e.png";
import s1 from "../../image/students/s1.jpg";
import s2 from "../../image/students/s2.jpg";
import s3 from "../../image/students/s3.jpg";
import paTopIcon from "../../image/newImg/pa.png";
import "./Content.css";

function Parents(props) {
  const { cover, sidebar_data, side_heading, side_sub_heading } = props;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isShow, steIsShow] = useState(false);
  const handleScroll = (e) => {
    if (e.target.scrollTop > 0 && !isScrolled) {
      setIsScrolled(true);
    }
    if (e.target.scrollTop === 0) {
      setIsScrolled(false);
    }
  };
  const handleClick = () => {
    steIsShow(!isShow);
    console.log(isShow);
  };

  return (
    <div className="content_main" onScroll={handleScroll}>
      <Navbar isScrolled={isScrolled} handleClick={handleClick} />
      <NavbarMenu isShow={isShow} handleClick={handleClick} />
      <Landing video={false} cover={cover} />
      <div className="contentmain_cont">
        <div style={{ width: "min-content" }}>
          <SideBar
            sidebar_data={sidebar_data}
            side_heading={side_heading}
            side_sub_heading={side_sub_heading}
          />
          <Contact />
        </div>
        <div>
          {/* parents' voice */}
          <div className={`f-2 f-500 mb-3`} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{ width: "4rem", marginRight: "1rem" }}
              alt=""
            />
            Parents' Voice
          </div>
          <div className={`parents_voice`} style={{ marginTop: "1.5rem" }}>
            <div className="parentimage">
              <img
                src={b}
                style={{
                  width: "10rem",
                  height: "10rem",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                  border: "3px solid #1FDC00F5",
                }}
                alt=""
              />
            </div>
            <div className={`voice_text`}>
              <p style={{ marginTop: "1rem" }}>
                <b>Harshdeep Singh Mehta (VII B)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                We are very satisfied. I am impressed with the curriculum and
                teaching methods of Hermann Gmeiner School, Jammu, which is
                different from other schools. The child is progressing well
                academically. Good efforts by all teachers. I am very grateful
                to all teachers, Coordinator and Principal. My child is being
                groomed very well. Very happy with the school system and with
                the cooperative attitude of the teachers for making my child
                more confident.
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voicetext">
              <p style={{ marginTop: "1rem" }}>
                <b>Saksham Dhar (IX A)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                We are impressed with the environment of the school, the
                friendly nature of the teachers who treat the students as their
                own children and with what excellence they teach! It’s
                impeccable. Whether it be games, sports, academics or any field,
                the school and the teachers are the building blocks of the
                institution. We are satisfied with the result.
              </p>
            </div>
            <div className="parentimage">
              <img
                src={c}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>
            <div className="parentimage">
              <img
                src={d}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
            <div className="voice_text">
              <p style={{ marginTop: "1rem" }}>
                <b>Ashley Koul (X B)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am fully satisfied with the school staff and management that
                has established a positive and accommodating environment for all
                children. The students feel respected, safe and connected with
                the staff and are engaged in learning. The best part that I
                liked about the school is the Extra Curricular Activities and
                Programmes organized by the school that provide a platform for
                the children to explore and exhibit their talent.
              </p>
            </div>
          </div>
          <div className="parents_voicee">
            <div className="voice_textt">
              <p style={{ marginTop: "1rem" }}>
                <b>Pragash Wangoo (IX B)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                It’s my pleasure to share with you that I’m satisfied with the
                teaching methods and the activities done in class room to help
                the students understand the concept. All the teachers are very
                hardworking and helpful.
              </p>
            </div>
            <div className="parentimage">
              <img
                src={e}
                style={{
                  width: "10rem",
                  height: "10rem",
                  border: "3px solid #1FDC00F5",
                  boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                }}
                alt=""
              />
            </div>
          </div>
          {/*  */}

          {/* students' voice */}
          {/* <div className={`f-2 f-500 mt-5 `} style={{ color: "#1FDC00ED" }}>
            <img
              src={paTopIcon}
              style={{
                width: "4rem",
                marginRight: "1rem",
                boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
              }}
            />
            Students' Voice
          </div> */}
          {/* <div style={{ marginTop: "1.5rem" }}>
            <img
              src={s1}
              style={{
                width: "10rem",
                height: "10rem",
                boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
                border: "3px solid #1FDC00F5",
              }}
            />
            <div style={{ marginLeft: "12rem", marginTop: "-11rem" }}>
              <p style={{ marginTop: "1rem" }}>
                <b>Kushleen Kaur (XII C)</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am so grateful for being a part of Hermann Gmeiner School,
                Faridabad. The teachers are kind and supportive. They have
                always guided me and gave me the strength I needed to achieve my
                goals. The school has given me many opportunities to show my
                best which increased my confidence. I will always miss my
                school.”
              </p>
            </div>
          </div>
          <div style={{ marginTop: "6rem", display: "flex" }}>
            <div>
              <p style={{ marginTop: "1rem" }}>
                <b>Nikitaa Shukla XII D </b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                The school has the friendly atmosphere and a best place for
                enhancing students skills. Teachers are very supportive.I feel
                proud to be a part of Hermann Gmeiner School Faridabad since
                2010.
              </p>
            </div>
            <img
              src={s2}
              style={{
                width: "10rem",
                height: "10rem",
                border: "3px solid #1FDC00F5",
                boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
              }}
            />
          </div>
          <div style={{ marginTop: "2rem", marginBottom: "3rem" }}>
            <img
              src={s3}
              style={{
                width: "10rem",
                height: "10rem",
                border: "3px solid #1FDC00F5",
                boxShadow: "10px 8px 4px rgba(0, 0, 0, 0.25)",
              }}
            />
            <div style={{ marginLeft: "12rem", marginTop: "-11rem" }}>
              <p style={{ marginTop: "1rem" }}>
                <b>Eshaa Shukla XII B</b>
              </p>
              <p style={{ marginTop: "1rem" }}>
                I am attending this school since 2010 and have only great things
                to say about my experience. The teachers are amazing,
                understanding and supportive.They teach their students very
                passionately and each child is treated with care.
              </p>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Parents;
